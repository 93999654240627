import { DELETE_DEVICE_COMMAND, UPDATE_DEVICE_COMMAND, DELETE_DEVICE_COMMAND_BATCH, UPDATE_DEVICE_COMMAND_BATCH } from '@/schema/commands';

/**
 * Actions performed on a device command
 */
export default {
    methods: {
        fetchDeviceCommands(batch_id, status, page) {
            return this.$store.dispatch(
                'deviceCommandsShow/fetch_commands_by_status',
                { batch_id, status, page }
            ).catch((error) => {
                this.$toast.error(error.message);
            });
        },
        fetchDeviceCommandsReport(batch_id) {
            return this.$store.dispatch(
                'deviceCommandsShow/fetch_count_by_status',
                { batch_id }
            ).catch((error) => {
                this.$toast.error(error.message);
            });
        },
        performUpdateDeviceCommand(device_id, project_id, device_command_id, command_name, status, message) {
            return this.$apollo.mutate({
                mutation: UPDATE_DEVICE_COMMAND,
                variables: {
                    deviceId: device_id,
                    projectId: project_id,
                    shortName: command_name,
                    input: {
                        device_command_id,
                        status,
                        message
                    }
                }
            });
        },
        performUpdateDeviceCommandBatchByStatus(batch_id, fromStatus, command_name, toStatus, message) {
            return this.$apollo.mutate({
                mutation: UPDATE_DEVICE_COMMAND_BATCH,
                variables: {
                    batch_id,
                    status: fromStatus,
                    input: {
                        command_name,
                        status: toStatus,
                        message
                    }
                },
            });
        },
        performDeleteDeviceCommand(device_id, project_id, device_command_id, command_name) {
            return this.$apollo.mutate({
                mutation: DELETE_DEVICE_COMMAND,
                variables: {
                    deviceId: device_id,
                    projectId: project_id,
                    shortName: command_name,
                    input: {
                        device_command_id,
                        status: '',
                        message: '',
                    }
                }
            });
        },
        performDeleteDeviceCommandBatchByStatus(batch_id, from_status) {
            return this.$apollo.mutate({
                mutation: DELETE_DEVICE_COMMAND_BATCH,
                variables: { batch_id, status: from_status }
            });
        }
    }
};
