export function parseFundamentumError(error) {

    let errorList = {};
    let last_message = '';

    if (error.graphQLErrors && error.graphQLErrors.length) {

        for (let g = 0, c = error.graphQLErrors.length; g < c; g++) {
            const err = error.graphQLErrors[g];
            if (!err.message)
                return [{}, err];

            let error_message = null;
            try {
                //try to parse json message
                error_message = JSON.parse(err.message);
            } catch (e) {
                return [{}, err.message];
            }
            let keys = Object.keys(error_message);

            for (let i = 0, c = keys.length; i < c; i++) {
                let key = keys[i];
                last_message = error_message[key][0];

                let s = key.split('.');
                if (s.length > 1) {
                    key = s[0];
                }

                errorList[key] = last_message;
            }
        }
    } else if (error.networkError) {

        for (let n = 0, c = error.networkError.result.errors.length; n < c; n++) {
            let err = error.networkError.result.errors[n];
            let key = err.path[err.path.length - 1];

            last_message = key + ' ' + err.message;
            errorList[key] = last_message;
        }

    }

    return [errorList, last_message];
}
