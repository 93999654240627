import { VIEW_BATCH_COMMANDS, VIEW_BATCH_COMMANDS_REPORT } from '@/schema/commands';
import { DEFAULT_PAGINATION } from '@/schema/common';

export default {
    namespaced: true,
    state: {
        count_by_status: {
            unassigned: 0,
            pending: 0,
            ongoing: 0,
            success: 0,
            cancel: 0,
            failure: 0,
        },
        commands_by_status: {
            unassigned: DEFAULT_PAGINATION,
            pending: DEFAULT_PAGINATION,
            ongoing: DEFAULT_PAGINATION,
            success: DEFAULT_PAGINATION,
            cancel: DEFAULT_PAGINATION,
            failure: DEFAULT_PAGINATION,
        },
        query_states: {
            fetch_commands_by_status: 'idle',
            fetch_count_by_status: 'idle'
        }
    },
    getters: {
        count_by_status: state => state.count_by_status,
        commands_by_status: state => state.commands_by_status,
        is_status_to_refresh: state => status => state.count_by_status[status] !== state.commands_by_status[status].pagination.total,
        is_querying: (state) => {
            let querying = false;
            for (const query in state.query_states) {
                querying = querying || state.query_states[query] === 'active';
            }
            return querying;
        }
    },
    mutations: {
        update_count_by_status(state, count_by_status) {
            for (const status in state.count_by_status) {
                state.count_by_status[status] = count_by_status[status] ?? 0;
            }
        },
        update_commands_by_status(state, payload) {
            state.commands_by_status[payload.status] = payload.commands ?? [];
        },
        reset_count_by_status(state) {
            state.count_by_status = {
                unassigned: 0,
                pending: 0,
                ongoing: 0,
                success: 0,
                cancel: 0,
                failure: 0,
            };
        },
        reset_commands_by_status(state) {
            state.commands_by_status = {
                unassigned: DEFAULT_PAGINATION,
                pending: DEFAULT_PAGINATION,
                ongoing: DEFAULT_PAGINATION,
                success: DEFAULT_PAGINATION,
                cancel: DEFAULT_PAGINATION,
                failure: DEFAULT_PAGINATION,
            };
        },
        switch_query_idle(state, query) {
            state.query_states[query] = 'idle';
        },
        switch_query_active(state, query) {
            state.query_states[query] = 'active';
        }
    },
    actions: {
        fetch_count_by_status: ({ commit, rootGetters }, payload) => {
            return new Promise((resolve, reject) => {
                commit('switch_query_active', 'fetch_count_by_status');
                rootGetters.apollo.query({
                    query: VIEW_BATCH_COMMANDS_REPORT,
                    variables: {
                        batchId: payload.batch_id,
                    },
                    fetchPolicy: 'no-cache',
                    notifyOnNetworkStatusChange: true,
                }).then(({ data, loading }) => {
                    if (!loading && data !== undefined) {
                        commit('update_count_by_status', data.batchCommandReport.count_by_status);
                    }
                    resolve();
                }).catch((error) => {
                    reject(error);
                }).finally(() => {
                    commit('switch_query_idle', 'fetch_count_by_status');
                });
            });
        },
        fetch_commands_by_status: ({ commit, rootGetters }, payload) => {
            return new Promise((resolve, reject) => {
                commit('switch_query_active', 'fetch_commands_by_status');
                rootGetters.apollo.query({
                    query: VIEW_BATCH_COMMANDS,
                    variables: {
                        batchId: payload.batch_id,
                        filters: {
                            with: ['device', 'command'],
                            wheres: [
                                {
                                    name: 'status',
                                    compare_with: '=',
                                    compare_to: payload.status,
                                }
                            ],
                            order_by: {
                                id: 'asc'
                            },
                            item_per_page: 15,
                            page: payload.page
                        }
                    },
                    fetchPolicy: 'no-cache',
                }).then(({ data, loading }) => {
                    if (!loading && data !== undefined) {
                        commit('update_commands_by_status', { status: payload.status, commands: data.batchCommand });
                    }
                    resolve();
                }).catch((error) => {
                    reject(error);
                }).finally(() => {
                    commit('switch_query_idle', 'fetch_commands_by_status');
                });
            });
        },
    }
};
