import gql from 'graphql-tag';

export const CREATE_VERSION = gql`
    mutation storeVersion($input: VersionInput!) {
        storeVersion(input: $input) {
            id
            identifier
            name
            revision
            configuration
            device_type_id
            version_parent_id
            aws
        }
    }
`;

export const UPDATE_VERSION = gql`
    mutation updateVersion($id: Int!, $input: VersionInput!) {
        updateVersion(id: $id, input: $input) {
            id
            identifier
            name
            revision
            configuration
            device_type_id
            version_parent_id
            aws
        }
    }
`;
