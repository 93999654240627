<template>
    <div id="chart">
        <apexchart type="heatmap" height="350" :options="chartOptions" :series="series"></apexchart>
    </div>
</template>

<script>

export default {
    name: 'heatmap',
    components: {},
    props: ['series_data'],

    watch: {
        series_data() {
            this.series = this.series_data;
        }
    },

    data() {
        return {
            series: this.series_data,
            chartOptions: {
                plotOptions: {
                    heatmap: {
                        shadeIntensity: 0.5,
                        radius: 0,
                        useFillColorAsStroke: true,
                        colorScale: {
                            ranges: [
                                {
                                    from: 0,
                                    to: 0,
                                    color: '#EF3E36'
                                },
                                {
                                    from: 1,
                                    to: 100,
                                    color: '#17BEBB'
                                }
                            ]
                        }
                    },
                },

                chart: {
                    height: 350,
                    type: 'heatmap',
                    toolbar: {
                        show: false
                    },
                },
                dataLabels: {
                    enabled: true,
                    style: {
                        colors: ['#323c48']
                    }
                },
                colors: ['#17BEBB'],
                stroke: {
                    width: 1,
                },
                xaxis: {
                    labels: {
                        style: {
                            colors: '#C5C5C5'
                        }
                    },
                    type: 'datetime',
                },
                yaxis: {
                    labels: {
                        style: {
                            colors: '#C5C5C5'
                        }
                    }
                },
            },
        };
    },
};

</script>
