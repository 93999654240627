import gql from 'graphql-tag';

export const VIEW_DEVICE_STATES = gql`
    query states($deviceId: Int64!, $projectId: Int64!, $filters: FiltersInput!) {
        states(deviceId: $deviceId, projectId: $projectId, filters: $filters) {
            data {
                time
                type
                data
            }
            pagination {
                total
                last_page
            }
        }
    }
`;

export const VIEW_STATE_TYPES = gql`
    query stateTypes($deviceId: Int64!, $projectId: Int64!)  {
        stateTypes(deviceId: $deviceId, projectId: $projectId)
    }
`;
export const VIEW_STATE_HEATMAP = gql`
    query heatMap($deviceId: Int64!, $projectId: Int64!)  {
        heatMap(deviceId: $deviceId, projectId: $projectId) {
            timestamp
            value
        }
    }
`;

export const SUBSCRIBE_LAST_STATES = gql`
    subscription liveLastStates($deviceId: Int64!, $projectId: Int64!) {
        liveLastStates(deviceId: $deviceId, projectId: $projectId) {
            device_id
            project_id
            timestamp
            states
        }
    }
`;

export const SUBSCRIBE_LAST_HEARTBEAT = gql`
    subscription liveLastHeartbeat($deviceId: Int64!, $projectId: Int64!) {
        liveLastHeartbeat(deviceId: $deviceId, projectId: $projectId) {
            uptime
            timestamp
            device_id
        }
    }
`;

export const SUBSCRIBE_SCANS = gql`
    subscription onScans($deviceId: Int64!, $projectId: Int64!) {
        liveScansEvents(deviceId: $deviceId, projectId: $projectId) {
            timestamp
            gateway_serial_number
            device_id
            scans{
                rssi
                device_id
                beacon_serial_number
            }
        }
    }
`;
