import {
    CREATE_DEVICE, DELETE_DEVICE,
    SEARCH_DEVICES,
    SUBSCRIPTION_DEVICE, UPDATE_DEVICE, UPDATE_DEVICE_AUTHENTICATION,
    VIEW_DEVICE_AUTHENTICATION,
    VIEW_DEVICES
} from '../../schema/devices';
import { SUBSCRIBE_LAST_STATES, SUBSCRIBE_LAST_HEARTBEAT, VIEW_STATE_HEATMAP } from '../../schema/states';

export default {
    namespaced: true,
    state: {
        device: null,
        last_states: {
            states: null,
            timestamp: null,
        },
        last_heartbeat: {
            up_time: null,
            last_heartbeat_at: null
        }
    },
    getters: {
        getDevice: (state) => {
            return state.device;
        },
        getLastStates: (state) => {
            return state.last_states;
        },
        getLastHeartbeat: (state) => {
            return state.last_heartbeat;
        }
    },
    mutations: {
        set_device: (state, device) => {
            state.device = device;
        },
        set_last_states: (state, states) => {
            state.last_states = states;
        },
        set_last_heartbeat: (state, heartbeat) => {
            state.last_heartbeat = heartbeat;
        },
    },
    actions: {
        get: ({ commit, dispatch }, payload) => {

            return new Promise((resolve, reject) =>{
                commit('set_device', null);
                commit('set_last_states', {
                    states: null,
                    timestamp: null,
                });
                commit('set_last_heartbeat', {
                    up_time: null,
                    last_heartbeat_at: null,
                });
                commit('deviceCommands/set_device_commands', [], { root: true });

                dispatch('subscribe', payload).then((device) => {
                    resolve(device);
                }).catch((error) => {
                    reject(error);
                });

                dispatch('lastSatesSubscribe', payload).then(() => {});
                dispatch('lastHeartbeatSubscribe', payload).then(() => {});
                dispatch('deviceCommands/subscribe', payload, { root: true }).then(() => {});
                dispatch('deviceActions/subscribe', payload, { root: true }).then(() => {});
            });
        },

        subscribe: ({ commit, rootGetters }, payload) => {
            return new Promise((resolve, reject) => {
                rootGetters.apollo.subscribe({
                    query: SUBSCRIPTION_DEVICE,
                    variables: {
                        deviceId: payload.device_id,
                        projectId: payload.project_id,
                    }
                }).subscribe({
                    next(message) {
                        const device = message.data.liveDevice;
                        if ( device.id === payload.device_id ) {
                            commit('set_device', device);
                        }

                        resolve(message.data.liveDevice);
                    },
                    error(error) {
                        console.error('error', error);
                        reject(error);
                    },
                });
            });
        },

        lastSatesSubscribe: ({ commit, state, rootGetters }, payload) => {
            rootGetters.apollo.subscribe({
                query: SUBSCRIBE_LAST_STATES,
                variables: {
                    deviceId: payload.device_id,
                    projectId: payload.project_id,
                }
            }).subscribe({
                next(message) {
                    const last_states = message.data.liveLastStates;

                    if (
                        (state.device && last_states.device_id === state.device.id) ||
                        (state.device === null && last_states.device_id === payload.device_id)
                    ) {
                        commit('set_last_states', last_states);
                    }
                },
                error(error) {
                    console.error('error', error);
                },
            });
        },
        lastHeartbeatSubscribe: ({ commit, state, rootGetters }, payload) => {
            rootGetters.apollo.subscribe({
                query: SUBSCRIBE_LAST_HEARTBEAT,
                variables: {
                    deviceId: payload.device_id,
                    projectId: payload.project_id,
                }
            }).subscribe({
                next(message) {
                    const lastHeartbeat = message.data.liveLastHeartbeat;

                    if (
                        (state.device && lastHeartbeat.device_id === state.device.id) ||
                        (state.device === null && lastHeartbeat.device_id === payload.device_id)
                    ) {
                        commit('set_last_heartbeat', { last_heartbeat_at: lastHeartbeat.timestamp, up_time: lastHeartbeat.uptime });
                    }
                },
                error(error) {
                    console.error('error', error);
                },
            });
        },

        getAuthentication: ({ rootGetters }, payload) => {
            return new Promise((resolve, reject) => {
                rootGetters.apollo.query({
                    query: VIEW_DEVICE_AUTHENTICATION,
                    variables: {
                        deviceId: payload.deviceId,
                        projectId: payload.projectId,
                    },
                    fetchPolicy: 'no-cache',
                    notifyOnNetworkStatusChange: true
                }).then(({ data, loading }) => {
                    if ( !loading && data !== undefined ) {
                        resolve(data.deviceAuthentication);
                    }
                }).catch((error) => {
                    console.error(error.message);
                    reject(error);
                });
            });

        },

        store: ({ commit, rootGetters }, payload) => {
            return new Promise((resolve, reject) =>{
                rootGetters.apollo.query({
                    query: CREATE_DEVICE,
                    variables: {
                        projectId: payload.project_id,
                        device: payload.device,
                    },
                    fetchPolicy: 'no-cache',
                    notifyOnNetworkStatusChange: true
                }).then(({ data, loading }) => {
                    if (!loading && data !== undefined) {
                        commit('set_device', data.storeDevice);

                        resolve(data.storeDevice);
                    }
                }).catch((error) => {
                    console.error(error.message);
                    reject(error);
                });
            });
        },
        update: ({ commit, rootGetters }, payload) => {
            return new Promise((resolve, reject) =>{
                rootGetters.apollo.query({
                    query: UPDATE_DEVICE,
                    variables: {
                        deviceId: payload.deviceId,
                        projectId: payload.projectId,
                        input: payload.device,
                    },
                    fetchPolicy: 'no-cache',
                    notifyOnNetworkStatusChange: true
                }).then(({ data, loading }) => {
                    if (!loading && data !== undefined) {
                        commit('set_device', data.updateDevice);
                        resolve(data.updateDevice);
                    }
                }).catch((error) => {
                    reject(error);
                });
            });
        },
        delete: ({ commit, rootGetters }, payload) => {
            return new Promise((resolve, reject) =>{
                rootGetters.apollo.query({
                    query: DELETE_DEVICE,
                    variables: {
                        deviceId: payload.deviceId,
                        projectId: payload.projectId,
                    },
                    fetchPolicy: 'no-cache',
                    notifyOnNetworkStatusChange: true
                }).then(({ data, loading }) => {
                    if (!loading && data !== undefined) {
                        commit('set_device', data.deleteDevice);
                        resolve(data.deleteDevice);
                    }
                }).catch((error) => {
                    console.error(error);
                    reject(error);
                });
            });
        },

        postAuthentication: ({ rootGetters }, payload) => {
            return new Promise((resolve, reject) => {
                rootGetters.apollo.query({
                    query: UPDATE_DEVICE_AUTHENTICATION,
                    variables: {
                        deviceId: payload.deviceId,
                        projectId: payload.projectId,
                        input: payload.deviceAuthentication
                    },
                    fetchPolicy: 'no-cache',
                    notifyOnNetworkStatusChange: true
                }).then(({ data, loading }) => {
                    if ( !loading && data !== undefined ) {
                        resolve(data.storeDeviceAuthentication);
                    }
                }).catch((error) => {
                    console.error(error.message);
                    console.error('error', error);
                    reject({ graphQLErrors: error } );
                });
            });

        },

        list: ({ rootGetters }, payload) => {

            return new Promise((resolve, reject) =>{

                rootGetters.apollo.query({
                    query: VIEW_DEVICES,
                    variables: {
                        projectId: parseInt(payload.project_id),
                        filters: payload.filters,
                    },
                    fetchPolicy: 'no-cache',
                    notifyOnNetworkStatusChange: true
                }).then(({ data, loading }) => {
                    if (!loading && data !== undefined) {

                        //commit("set_devices", data.devices.data);
                        //commit("set_pagination", data.devices.pagination);

                        resolve(data.devices);
                    }
                }).catch((error) => {
                    console.error(error.message);
                    reject(error);
                });

            });
        },

        search: ({ rootGetters }, payload) => {

            let filters = {
                page: payload.page,
                item_per_page: 5,
                or_wheres: [
                    {
                        compare_to: '%25' + payload.search_string + '%25',
                        compare_with: 'LIKE',
                        name: 'name'
                    },
                    {
                        compare_to: `%25${payload.search_string}%25`,
                        compare_with: 'LIKE',
                        name: 'serial_number'
                    }
                ],
                with: ['device_type', 'registry'],
                with_trashed: payload.with_trashed
            };

            return new Promise((resolve, reject) =>{

                rootGetters.apollo.query({
                    query: SEARCH_DEVICES,
                    variables: {
                        projectId: payload.project_id,
                        filters: filters
                    },
                    fetchPolicy: 'no-cache',
                    notifyOnNetworkStatusChange: true
                }).then(({ data, loading }) => {
                    if (!loading && data !== undefined) {
                        resolve(data.devices);
                    }
                }).catch((error) => {
                    console.error(error.message);
                    reject(error);
                });

            });
        },

        getDevicesStatesHeatmap: ({ rootGetters }, payload) => {

            return new Promise((resolve, reject) =>{

                rootGetters.apollo.query({
                    query: VIEW_STATE_HEATMAP,
                    variables: {
                        projectId: payload.project_id,
                        deviceId: payload.device_id,
                    },
                    fetchPolicy: 'no-cache',
                    notifyOnNetworkStatusChange: true
                }).then(({ data, loading }) => {
                    if (!loading && data !== undefined) {
                        resolve(data.heatMap);
                    }
                }).catch((error) => {
                    console.error(error.message);
                    reject(error);
                });

            });
        },
    }
};
