
export default {
    namespaced: true,
    state: {
        menu_collapsed: false,
        auto_refresh_timeout: 30000,
        saved_devices_list: {}
    },
    getters: {
        collapse: (state) => state.menu_collapsed,

        autoRefreshTimeout: (state) => state.auto_refresh_timeout,

        savedDevicesListByRegistry: (state) => (registry_id) => {
            if ( state.saved_devices_list[registry_id] === undefined )
                return [];

            return state.saved_devices_list[registry_id];
        }
    },
    mutations: {
        toggle_menu_collapse: (state) => {
            state.menu_collapsed = !state.menu_collapsed;
        },

        setAutoRefreshTimeout: (state, timeout) => {
            state.auto_refresh_timeout = timeout;
        },

        saveDevicesList: (state, payload) => {
            state.saved_devices_list[payload.registry_id] = payload.devices;
        }
    },
    actions: {
    }
};
