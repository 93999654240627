export const DEFAULT_PAGINATION = {
    items: [],
    data: [],
    pagination: {
        last_page: 0,
        total: 0,
    },
    totalPages: 0,
    totalEntries: 0,
    perPage: 25
};
