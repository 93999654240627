import {
    SUBSCRIPTION_ACTION_TYPES,
    CREATE_ACTION_TYPE,
    UPDATE_ACTION_TYPE,
    DELETE_ACTION_TYPE
} from '../../../schema/actions';

export default {
    namespaced: true,
    state: {
        action_types: [],
    },
    getters: {
        //Selected (current) project of the selected Business Unit
        action_types: (state) => {
            return state.action_types;
        },

        action_type_by_id: (state) => (id) => {
            return state.action_types.find(item => item.id === id);
        }
    },
    mutations: {
        clear: (state) => {
            state.action_types = [];
        },
        update: (state, item) => {
            const index = state.action_types.findIndex(a => a.id === item.id);
            if (index !== -1) {
                state.action_types.splice(index, 1, item);
            } else {
                state.action_types.push(item);
            }
        },
        delete: (state, id) => {
            const index = state.action_types.findIndex(a => a.id === id);
            if (index !== -1) {
                state.action_types.splice(index, 1);
            }
        },
    },
    actions: {
        subscribe: ({ commit, rootGetters }, payload) => {
            commit('clear');

            rootGetters.apollo.subscribe({
                query: SUBSCRIPTION_ACTION_TYPES,
                variables: {
                    projectId: payload.project_id
                }
            }).subscribe({
                async next(message) {
                    const action_type = message.data.liveActionType;

                    if ( action_type.deleted_at && action_type.deleted_at !== '' ) {
                        commit('delete', action_type.id);
                    } else  {
                        commit('update', action_type);
                    }

                },
                error(error) {
                    console.error('error', error);
                }
            });

        },

        storeOrUpdate: ({ commit, rootGetters }, payload) => {
            let typeName = payload.create ? 'storeActionType' : 'updateActionType';

            return new Promise((resolve, reject) =>{
                rootGetters.apollo.mutate({
                    mutation: payload.create ? CREATE_ACTION_TYPE : UPDATE_ACTION_TYPE,
                    variables: payload.variables,
                    fetchPolicy: 'no-cache',
                    notifyOnNetworkStatusChange: true
                }).then(({ data, loading }) => {
                    if (!loading && data !== undefined) {
                        commit('update', data[typeName]);
                        resolve();
                    }
                }).catch((error) => {
                    console.error(error.message);
                    reject(error);
                });
            });
        },

        delete: ({ commit, rootGetters }, payload) => {
            return new Promise((resolve, reject) =>{
                rootGetters.apollo.mutate({
                    mutation: DELETE_ACTION_TYPE,
                    variables: {
                        id: payload.id,
                        projectId: payload.project_id
                    },
                    fetchPolicy: 'no-cache',
                    notifyOnNetworkStatusChange: true
                }).then(({ data, loading }) => {
                    if (!loading && data !== undefined) {
                        commit('delete', payload.id);
                        resolve();
                    }
                }).catch((error) => {
                    console.error(error.message);
                    reject(error);
                });
            });
        },
    }
};
