<template>
    <span class="navbar-item is-expanded">
        <div class="dropdown  is-expanded" :class="{ 'is-active': is_focus }" v-click-outside="hide_menu">

            <div class="dropdown-trigger">
                <div class="field">
                    <p class="control is-expanded has-icons-left">
                        <input class="input" type="search" placeholder="Search device..." @input="onKeyDown"
                            @focus="is_focus = true">
                        <span class="icon is-small is-left">
                            <fa prefix="fa" icon="search" fixed-width />
                        </span>
                    </p>
                </div>
            </div>

            <div class="dropdown-menu" id="dropdown-user-menu" role="menu">
                <div class="dropdown-content">

                    <div class="dropdown-item">
                        <div class="card" :class="{ 'loading': loading }">
                            <div class="card-content">
                                <div class="field">
                                    <label class="checkbox">
                                        <input type="checkbox" :value="true" v-model="with_trashed">
                                        Include archived devices
                                    </label>

                                </div>
                            </div>
                        </div>
                    </div>

                    <hr class="dropdown-divider">

                    <router-link class="dropdown-item" v-for="device in search_result" :key="device.id"
                        :to="'/devices/' + device.id + '/edit'" @click.native="is_focus = false">
                        <div class="card" :class="{ 'loading': loading }">
                            <div class="card-content">
                                <p class="title"
                                    :class="{ 'strike': (device.deleted_at !== '' && device.deleted_at != null) }">
                                    <progress v-if="loading" class="progress is-large" max="100"></progress>
                                    <span v-else>
                                        <fa v-if="device.device_type && device.device_type.icon"
                                            :icon="device.device_type.icon.value" fixed-width></fa>
                                        {{ device.name }}
                                    </span>
                                </p>
                                <p class="subtitle">
                                    <progress v-if="loading" class="progress is-large" max="100"></progress>
                                    <span v-else>SN#: {{ device.serial_number }}</span>
                                </p>
                            </div>
                            <footer class="card-footer">
                                <p class="card-footer-item">
                                    <progress v-if="loading" class="progress is-large" max="100"></progress>
                                    <span v-else>
                                        Registry: {{ registry(device) }}
                                    </span>
                                </p>
                            </footer>
                        </div>
                    </router-link>

                    <hr class="dropdown-divider">

                    <div class="dropdown-item is-text-center" v-if="total_pages > 1">
                        Result {{ (page - 1) * 5 + 1 }} to {{ Math.min(total_entries, (page - 1) * 5 + 5) }} of {{ total_entries
                        }}<br>
                        <button class="button is-primary is-outlined" @click="nextPage">
                            <span v-if="page < total_pages">Load More</span>
                            <span v-else>Reset</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>

    </span>
</template>

<script>
import debounce from 'debounce';

export default {
    name: 'NavbarDeviceSearch',
    components: {},
    data() {
        return {
            search_string: '',
            search_result: [],
            loading: false,
            devices: [],
            is_focus: null,
            with_trashed: false,
            page: 1,
            total_entries: 0,
            total_pages: 0,
        };
    },

    computed: {
        project_id() {
            return this.$store.getters['businessUnit/project'].id;
        },
    },

    watch: {
        with_trashed: function () {
            this.loading = true;
            this.performDeviceSearch();
        }
    },

    methods: {
        onKeyDown: function (e) {
            if (e.target.value !== '') {
                this.loading = true;
                this.search_string = e.target.value;
                return this.debounced(this.performDeviceSearch);
            } else {
                this.loading = false;
                let that = this;

                this.debounced(function () {
                    that.$store.commit('devices/set_found_device', []);
                });
            }
        },

        debounced: debounce(function (func) {
            this.page = 1;
            func();
        }, 500),

        performDeviceSearch: function () {
            this.$store.dispatch('devices/search', {
                project_id: this.project_id,
                search_string: this.search_string,
                with_trashed: this.with_trashed,
                page: this.page,
            })
                .then((response) => {
                    this.search_result = response.data;
                    this.total_entries = response.pagination.total;
                    this.total_pages = response.pagination.last_page;
                })
                .finally(() => {
                    this.loading = false;
                });

        },

        hide_menu() {
            this.is_focus = false;
        },

        registry(device) {
            return device?.registry?.name;
        },

        nextPage() {
            this.page += 1;
            if (this.page > this.total_pages)
                this.page = 1;

            this.loading = true;
            this.performDeviceSearch();
        },
    },

};
</script>

<style scoped lang="scss">
.strike {
    text-decoration: line-through;
    opacity: .5;
}

.dropdown-item {
    padding: 0.375rem 0.5rem;
}

.card-content {
    padding: 0.5rem;

    .title {
        font-size: 1.5rem;
    }
}

.card-footer-item {
    padding: 0.5rem;
}

.dropdown-item:hover .card .card-content .title {
    color: white;
}

.progress {
    min-width: 150px;
    animation-duration: 1s;
}

.dropdown {
    width: 100%;

    .dropdown-trigger {
        width: 100%
    }

    .button {
        display: flex;
        width: 100%;
        justify-content: space-between
    }

}
</style>
