export default class Configuration {
    static VUE_APP_OAUTH_SERVER_URL = process.env.VUE_APP_OAUTH_SERVER_URL;
    static VUE_APP_REMOTE_SSH_WEB_SOCKET_URL = process.env.VUE_APP_REMOTE_SSH_WEB_SOCKET_URL;
    static VUE_APP_OAUTH_AUTHORIZE_ROUTE = process.env.VUE_APP_OAUTH_AUTHORIZE_ROUTE;
    static VUE_APP_OAUTH_LOGOUT_ROUTE = process.env.VUE_APP_OAUTH_LOGOUT_ROUTE;
    static VUE_APP_OAUTH_CHECK_ROUTE = process.env.VUE_APP_OAUTH_CHECK_ROUTE;
    static VUE_APP_GRAPHQL_URL = process.env.VUE_APP_GRAPHQL_URL;
    static VUE_APP_GRAPHQL_WEB_SOCKET = process.env.VUE_APP_GRAPHQL_WEB_SOCKET;
    static VUE_APP_OAUTH_CLIENT_ID = process.env.VUE_APP_OAUTH_CLIENT_ID;
    static VUE_APP_OAUTH_REDIRECT_URL = process.env.VUE_APP_OAUTH_REDIRECT_URL;
    static VUE_APP_OAUTH_RESPONSE_TYPE = process.env.VUE_APP_OAUTH_RESPONSE_TYPE;
    static VUE_APP_OAUTH_SCOPE = process.env.VUE_APP_OAUTH_SCOPE;
    static VUE_APP_URL = process.env.VUE_APP_URL;
    static VUE_APP_VERSION = process.env.VUE_APP_VERSION;
    static VUE_APP_FEATURES_CONNECTORS_ACTIVATED = process.env.VUE_APP_FEATURES_CONNECTORS_ACTIVATED;
}
