import {
    SUBSCRIPTION_DEVICE_ACTIONS,
    LIST_DEVICE_ACTIONS,
    UPDATE_DEVICE_ACTION,
} from '../../../schema/deviceActions';

import { UpdateListByItemId } from '@/utils/helpers.ts';

export default {
    namespaced: true,
    state: {
        actives: [],
        listing: [],
    },
    getters: {
        //Selected (current) project of the selected Business Unit
        actives: (state) => {
            return state.actives;
        },

        list: (state) => {
            return state.listing;
        },

        by_id: (state) => (id) => {
            return state.actives.find((item) => item.id === id);
        },
    },
    mutations: {
        clear: (state) => {
            state.actives = [];
        },
        update: (state, item) => {
            UpdateListByItemId(state.actives, item);
        },
        update_by_id: (state, item) => {
            UpdateListByItemId(state.actives, item);

            const index = state.listing.findIndex((a) => a.id === item.id);
            if (index !== -1) {
                state.listing.splice(index, 1, item);
            }
        },
        delete: (state, id) => {
            const index = state.actives.findIndex((a) => a.id === id);
            if (index !== -1) {
                state.actives.splice(index, 1);
            }
        },
        set_listing: (state, items) => {
            state.listing = items;
        },
    },
    actions: {
        subscribe: ({ commit, rootGetters }, payload) => {
            commit('clear');

            rootGetters.apollo
                .subscribe({
                    query: SUBSCRIPTION_DEVICE_ACTIONS,
                    variables: {
                        projectId: payload.project_id,
                        deviceId: payload.device_id,
                    },
                })
                .subscribe({
                    async next(message) {
                        const item = message.data.liveDeviceAction;

                        if (item.deleted_at && item.deleted_at !== '') {
                            commit('delete', item.id);
                        } else {
                            commit('update', item);
                        }
                    },

                    error(error) {
                        console.error('error', error);
                    },
                });
        },

        list: ({ commit, rootGetters }, payload) => {
            return new Promise((resolve, reject) => {
                rootGetters.apollo
                    .query({
                        query: LIST_DEVICE_ACTIONS,
                        variables: {
                            projectId: payload.project_id,
                            deviceId: payload.device_id,
                            filters: payload.filters,
                        },
                    })
                    .then(({ data, loading }) => {
                        if (!loading && data !== undefined) {
                            commit('set_listing', data.deviceActions.data);
                            resolve(data.deviceActions);
                        }
                    })
                    .catch((error) => {
                        console.error(error.message);
                        reject(error);
                    });
            });
        },

        update: ({ commit, rootGetters }, payload) => {
            return new Promise((resolve, reject) => {
                rootGetters.apollo
                    .mutate({
                        mutation: UPDATE_DEVICE_ACTION,
                        variables: {
                            projectId: payload.project_id,
                            id: payload.device_action_id,
                            input: {
                                status: payload.status,
                            },
                        },
                        fetchPolicy: 'no-cache',
                    })
                    .then(({ data, loading }) => {
                        if (!loading && data !== undefined) {
                            commit('update_by_id', data.updateDeviceAction);
                            resolve(data.updateDeviceAction);
                        }
                    })
                    .catch((error) => {
                        console.error(error.message);
                        reject(error);
                    });
            });
        },
    },
};
