export function secondsToString(seconds) {
    let num_years = Math.floor(seconds / 31536000);
    let num_days = Math.floor((seconds % 31536000) / 86400);
    let num_hours = Math.floor(((seconds % 31536000) % 86400) / 3600);
    let num_minutes = Math.floor((((seconds % 31536000) % 86400) % 3600) / 60);
    let num_seconds = (((seconds % 31536000) % 86400) % 3600) % 60;

    let return_str = '';

    if (num_years > 0) return_str += num_years + ' years ';
    if (num_days > 0) return_str += num_days + ' days ';
    if (num_hours > 0) return_str += num_hours + ' hours ';
    if (num_minutes > 0) return_str += num_minutes + ' min ';
    if (num_seconds > 0) return_str += num_seconds + ' sec';

    return return_str;
}

export function isALoraDevice(device) {
    return (
        device.communication_methods.map((e) => e.name).indexOf('Lora') !== -1
    );
}

/**
 * Function used in vuetable-2 to prevent injection.
 * @param str
 * @returns
 */
export function escapeHtml(str) {
    if (!str) {
        return str;
    }
    return str.replace(/[&<>"'/]/g, (char) => {
        switch (char) {
            case '&':
                return '&amp;';
            case '<':
                return '&lt;';
            case '>':
                return '&gt;';
            case '"':
                return '&quot;';
            case `'`:
                return '&#39;';
            case '/':
                return '&#x2F;';
            default:
                return char;
        }
    });
}

export function UpdateListByItemId(list, item) {
    const index = list.findIndex((a) => a.id === item.id);
    if (index !== -1) {
        list.splice(index, 1, item);
    } else {
        list.push(item);
    }
}

export function isInt(value) {
    return (
        !isNaN(value) &&
        (function (x) {
            return (x | 0) === x;
        })(parseFloat(value))
    );
}
