<template>
    <div class="columns">
        <div class="column">
            <h2 class="title is-2">{{ title }}</h2>
        </div>

        <div class="column is-narrow" v-if="button_action">
            <router-link :to="button_action" class="button is-primary is-pulled-right">
                {{ button_title }}
                <fa icon="plus-square" fixed-width />
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
    props: [
        'title',
        'button_title',
        'button_action'
    ],
};
</script>

<style></style>
