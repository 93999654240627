<template>
    <div id="chart">
        <apexchart type="radialBar" height="300" :options="chartOptions" :series="series"></apexchart>
    </div>
</template>

<script>

export default {
    name: 'radial-gradiant-chart',
    mixins: [],

    props: ['label', 'value'],

    components: {
    },

    data() {
        return {
            series: [this.value],
            chartOptions: {
                chart: {
                    height: 300,
                    type: 'radialBar',
                    toolbar: {
                        show: false
                    }
                },
                plotOptions: {
                    radialBar: {
                        startAngle: -135,
                        endAngle: 225,
                        hollow: {
                            margin: 0,
                            size: '70%',
                            background: '#323c48',
                            image: undefined,
                            imageOffsetX: 0,
                            imageOffsetY: 0,
                            position: 'front',
                            dropShadow: {
                                enabled: true,
                                top: 3,
                                left: 0,
                                blur: 4,
                                opacity: 0.24
                            }
                        },
                        track: {
                            background: '#242c35',
                            dropShadow: {
                                enabled: true,
                                top: 2,
                                left: 0,
                                color: '#242c35',
                                opacity: 0.1,
                                blur: 2
                            }
                        },

                        dataLabels: {
                            show: true,
                            name: {
                                offsetY: -10,
                                show: true,
                                color: '#C5C5C5',
                                fontSize: '17px'
                            },
                            value: {
                                formatter: function (val) {
                                    return parseInt(val) + '%';
                                },
                                color: '#C5C5C5',
                                fontSize: '36px',
                                show: true,
                            }
                        }
                    }
                },
                colors: ['#48c78e'],
                fill: {
                    type: 'gradient',
                    gradient: {
                        shade: 'dark',
                        type: 'horizontal',
                        shadeIntensity: 0.5,
                        gradientToColors: ['#17BEBB'],
                        inverseColors: true,
                        opacityFrom: 1,
                        opacityTo: 1,
                        stops: [0, 100]
                    }
                },
                stroke: {
                    lineCap: 'round'
                },
                labels: [this.label],
            },

        };
    },

    mounted() {
        this.loading = false;
    },

    computed: {},

    methods: {},
};
</script>
