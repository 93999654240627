import { LIST_ACTIONS, SUBSCRIPTION_ACTION } from '@/schema/actions';

import { UpdateListByItemId } from '@/utils/helpers.ts';

export default {
    namespaced: true,
    state: {
        listing: [],
        device_actions: [],
    },
    getters: {
        list: (state) => {
            return state.listing;
        },

        device_actions: (state) => {
            return state.device_actions;
        },
        progression: (state) => {
            return parseInt(
                state.device_actions.reduce((r, a) => r + a.progression, 0) /
                    state.device_actions.length,
            );
        },
    },
    mutations: {
        set_listing: (state, items) => {
            state.listing = items;
        },
        update: (state, item) => {
            UpdateListByItemId(state.device_actions, item);
        },
        delete: (state, id) => {
            const index = state.device_actions.findIndex((a) => a.id === id);
            if (index !== -1) {
                state.device_actions.splice(index, 1);
            }
        },
        clear: (state) => {
            state.device_actions = [];
        },
    },
    actions: {
        list: ({ commit, rootGetters }, payload) => {
            return new Promise((resolve, reject) => {
                rootGetters.apollo
                    .query({
                        query: LIST_ACTIONS,
                        variables: {
                            projectId: payload.project_id,
                            filters: payload.filters,
                        },
                    })
                    .then(({ data, loading }) => {
                        if (!loading && data !== undefined) {
                            commit('set_listing', data.actions.data);
                            resolve(data.actions);
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        subscribe: ({ commit, rootGetters }, payload) => {
            commit('clear');

            rootGetters.apollo
                .subscribe({
                    query: SUBSCRIPTION_ACTION,
                    variables: {
                        projectId: payload.project_id,
                        actionId: payload.action_id,
                    },
                })
                .subscribe({
                    async next(message) {
                        const device_action = message.data.liveAction;

                        if (
                            device_action.deleted_at &&
                            device_action.deleted_at !== ''
                        ) {
                            commit('delete', device_action.id);
                        } else {
                            commit('update', device_action);
                        }
                    },
                    error(error) {
                        console.error('error', error);
                    },
                });
        },
    },
};
