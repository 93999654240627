import { LIST_DEVICE_COMMANDS, SUBSCRIBE_DEVICE_COMMANDS } from '../../schema/commands';

export default {
    namespaced: true,

    state: {
        device_commands: [],
        device_command_list: [],
    },

    getters: {
        getDeviceCommands: (state) => {
            return state.device_commands;
        },
        getDeviceCommandList: (state) => {
            return state.device_command_list;
        },
    },

    mutations: {
        set_device_commands: (state, device_commands) => {
            state.device_commands = device_commands;
        },
        set_device_command_list: (state, device_commands) => {
            state.device_command_list = device_commands;
        },
        push_device_commands: (state, device_command) => {
            const index = state.device_commands.findIndex(item => item.id === device_command.id);
            if (index !== -1) {
                state.device_commands.splice(index, 1, device_command);
            } else {
                state.device_commands.push(device_command);
            }

            const indexInList = state.device_command_list.findIndex(item => item.id === device_command.id);
            if (indexInList !== -1) {
                if (device_command.deleted_at !== null) {
                    state.device_command_list.splice(indexInList, 1);
                } else {
                    state.device_command_list.splice(indexInList, 1, device_command);
                }
            }
        },
    },

    actions: {
        subscribe: ({ commit, rootGetters }, payload) => {
            rootGetters.apollo.subscribe({
                query: SUBSCRIBE_DEVICE_COMMANDS,
                variables: {
                    deviceId: payload.device_id,
                    projectId: payload.project_id,
                }
            }).subscribe({
                next(message) {
                    const device_command = message.data.liveDeviceCommands;
                    const device = rootGetters['devices/getDevice'];

                    const isTargetDevice = (
                        (device_command.device_id === payload.device_id) ||
                        (device_command.parameters.target_device.id === payload.device_id)
                    );

                    if ( isTargetDevice &&
                        (
                            (device && payload.device_id === device.id) ||
                            device === null
                        )
                    ) {
                        commit('push_device_commands', device_command);
                    }
                },
                error(error) {
                    console.error('error', error);
                },
            });
        },

        list: ({ commit, rootGetters }, payload) => {

            return new Promise((resolve, reject) =>{

                rootGetters.apollo.query({
                    query: LIST_DEVICE_COMMANDS,
                    variables: {
                        deviceId: parseInt(payload.deviceId),
                        projectId: parseInt(payload.projectId),
                        filters: payload.filters,
                    },
                    fetchPolicy: 'no-cache',
                    notifyOnNetworkStatusChange: true
                }).then(({ data, loading }) => {
                    if (!loading && data !== undefined) {

                        commit('set_device_command_list', data.deviceCommands.data);
                        //commit("set_pagination", data.devices.pagination);

                        resolve(data.deviceCommands);
                    }
                }).catch((error) => {
                    console.error(error.message);
                    reject(error);
                });

            });
        },

    }
};
