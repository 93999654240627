
<template>
    <article class="message is-darker" v-if="rowData">
        <div class="message-body">
            <device-command-card-content :device_id="device_id" :command="rowData" />

            <div class="card-footer">

                <a href="#" class='card-footer-item' v-if="rowData.status !== 'pending'"
                    @click="retry_command(rowData)">Retry</a>
                <a href="#" class='card-footer-item' v-if="rowData.status === 'pending'"
                    @click="cancel_command(rowData)">Cancel</a>
                <a href="#" class='card-footer-item' v-else @click="delete_command(rowData)">Clear</a>

            </div>
        </div>
    </article>
</template>

<script>

import DeviceCommandCardContent from './DeviceCommandCardContent.vue';
import DevicesCommandsActionsMixin from '../../../mixins/devices-commands/DevicesCommandsActionsMixin';

export default {
    name: 'device-command-detail-row',
    mixins: [DevicesCommandsActionsMixin],
    components: { DeviceCommandCardContent },
    props: {
        rowData: {
            type: Object,
            required: true
        },
        rowIndex: {
            type: Number
        },
        options: {
            type: Object,
        }
    },

    computed: {

        project_id() {
            return this.$store.getters['businessUnit/project'].id;
        },
        device_id() {
            return this.$store.getters['devices/getDevice'].id;
        },
    },

    methods: {

        cancel_command(device_command) {
            this.update_device_command('cancel', 'Cancel by user', device_command);
        },

        retry_command(device_command) {
            this.update_device_command('retry', 'Retry ask by user', device_command);
        },

        update_device_command(status, message, device_command) {
            this.performUpdateDeviceCommand(
                this.device_id,
                this.project_id,
                device_command.id,
                device_command.command.short_name,
                status,
                message
            ).then(() => {
                this.$toast.success(`Command Canceled`);
                // this.$emit('input', false);
                //this.$root.$emit("update-command-list");
                //this.load();
            }).catch((error) => {
                this.$toast.error(error.message);
            });
        },

        delete_command(device_command) {
            if (!confirm('Delete the command?')) return;

            this.performDeleteDeviceCommand(
                this.device_id,
                this.project_id,
                device_command.id,
                device_command.command.short_name
            ).then(() => {
                this.$toast.success(`Command Canceled`);
                //this.load();
                // this.$emit('input', false);
                //this.$root.$emit("update-command-list");
            }).catch((error) => {
                this.$toast.error(error.message);
            });
        }
    },
};

</script>
