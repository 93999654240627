import gql from 'graphql-tag';

export const VIEW_PROTOCOLS = gql`
    query viewProtocols($filters: FiltersInput) {
        protocols(filters: $filters) {
            data {
                id
                name
            }
            pagination {
                total
                last_page
            }
        }
    }
`;

export const CREATE_PROTOCOL = gql`
    mutation storeProtocol($input: ProtocolInput!) {
        storeProtocol(input: $input) {
            id
            name
        }
    }
`;

export const UPDATE_PROTOCOL = gql`
    mutation updateProtocol($id: Int!, $input: ProtocolInput!) {
        updateProtocol(id: $id, input: $input) {
            id
            name
        }
    }
`;

export const VIEW_COMMUNICATIONS = gql`
    query viewCommunicationsMethods($filters: FiltersInput!) {
        communication_methods(filters: $filters) {
            data {
                id
                name
            }
            pagination {
                total
                last_page
            }
        }
    }
`;

export const CREATE_COMMUNICATION_METHOD = gql`
    mutation storeCommunicationMethod($input: CommunicationMethodInput!) {
        storeCommunicationMethod(input: $input) {
            id
            name
        }
    }
`;

export const UPDATE_COMMUNICATION_METHOD = gql`
    mutation updateCommunicationMethod($id: Int!, $input: CommunicationMethodInput!) {
        updateCommunicationMethod(id: $id, input: $input) {
            id
            name
        }
    }
`;

export const DELETE_COMMUNICATION_METHOD = gql`
    mutation deleteCommunicationMethod($id: Float!) {
        deleteCommunicationMethod(id: $id)
    }
`;

export const VIEW_CLOUD_PROVIDERS = gql`
    query viewCloudProviders {
        cloudProviders {
            data {
                id
                name
                configuration
            }
            pagination {
                total
                last_page
            }
        }
    }
`;
export const VIEW_CLOUD_PROVIDER = gql`
    query viewCloudProvider($id: Float!) {
        cloud_provider(id: $id) {
            id
            name
            configuration
        }
    }
`;

export const CREATE_CLOUD_PROVIDER = gql`
    mutation storeCloudProvider($input: CloudProviderInput!) {
        storeCloudProvider(input: $input) {
            id
            name
            configuration
        }
    }
`;

export const UPDATE_CLOUD_PROVIDER = gql`
    mutation updateCloudProvider($id: Int!, $input: CloudProviderInput!) {
        updateCloudProvider(id: $id, input: $input) {
            id
            name
            configuration
        }
    }
`;
