
let storageMock = {
    storage: {},

    setItem: function (key, value) {
        this.storage[key] = value || '';
    },
    getItem: function (key) {
        return key in this.storage ? this.storage[key] : null;
    },
    removeItem: function (key) {
        delete this.storage[key];
    },
    get length() {
        return Object.keys(this.storage).length;
    },
    key: function (i) {
        let keys = Object.keys(this.storage);
        return keys[i] || null;
    }
};

let storageFactory = {

    make: function () {

        if (typeof localStorage !== 'undefined')
            return localStorage;

        return storageMock;
    }

};

export default storageFactory;