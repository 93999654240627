<template>
  <div class="navbar-item has-dropdown is-hoverable">

    <a v-if="!isOAuthTokenValid" class="navbar-link">
      Login <fa icon="sign-in-alt" fixed-width></fa>
    </a>

    <a v-else-if="user" class="navbar-link">
      <span class="oauth-user-name">
          <span style="margin-right: 5px">{{ user.name }}</span>
          <fa icon="user" fixed-width></fa>
      </span>
      <span class="icon is-small">
          <fa icon="angle-down" fixed-width aria-hidden="true"></fa>
      </span>
    </a>

    <div class="navbar-dropdown" v-if="user">
      <a class="navbar-item">
        <p>Welcome <strong>{{ user.name }}</strong></p>
      </a>

      <hr class="navbar-divider">

      <a class="navbar-item" v-if="project">
        <strong>{{ project.name }}</strong>
      </a>

      <router-link class="navbar-item" to="/oauth/project-selector">
        {{ project ? 'Switch' : 'Select' }} Project
        <fa icon="exchange-alt" fixed-width></fa>
      </router-link>

      <hr class="navbar-divider">

      <div v-if="isAdmin">

        <router-link to="/protocols" class="navbar-item" @click.native="hide_menu">
          <fa icon="satellite-dish" fixed-width></fa> Protocols
        </router-link>

        <router-link to="/communication-methods" class="navbar-item" @click.native="hide_menu">
          <fa icon="tty" fixed-width></fa> Comm. Methods
        </router-link>

        <router-link to="/providers" class="navbar-item" @click.native="hide_menu">
          <fa icon="cloud" fixed-width></fa> Service Providers
        </router-link>

        <hr class="navbar-divider">

      </div>

      <router-link class="navbar-item oauth-logout-button" to="/oauth/user">
        <fa icon="user" fixed-width></fa> User Account
      </router-link>

      <router-link class="navbar-item oauth-logout-button" to="/oauth/logout">
        <fa icon="sign-out-alt" fixed-width></fa> Logout
      </router-link>

    </div>

  </div>
</template>

<script>

export default {
    name: 'AdminMenuButton',

    data: () => {
        return {
        };
    },

    components: {},

    beforeRouteEnter(/*to, from, next*/) {

    },

    watch: {
        isOAuthTokenValid(to) {
            if (to) {
                this.getCurrentUser();
            }
        },
    },

    mounted: function () {
        if (this.isOAuthTokenValid && this.user === null)
            this.getCurrentUser();
    },

    computed: {
        isOAuthTokenValid() {
            return this.$store.getters['oauth/isOAuthTokenValid']();
        },
        user() {
            return this.$store.state.oauth.user;
        },
        isAdmin() {
            return this.$store.getters['oauth/currentUserIsAdmin'];
        },
        project() {
            return this.$store.getters['businessUnit/project'];
        },
        registry() {
            return this.$store.getters['registries/registry'];
        }
    },

    methods: {
        getCurrentUser() {
            this.$store.dispatch('oauth/get_current_user');
        },
    },

};
</script>
