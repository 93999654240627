<template>
    <div class="container">
        <div class="is-size-3">Oops! The page you are looking for is not found.</div>
        <div><router-link to="/">Back to home!</router-link></div>
    </div>
</template>

<script>
export default {

};
</script>

<style></style>