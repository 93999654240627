
import { DELETE_DEVICE_TYPE, SUBSCRIPTION_DEVICE_TYPES, VIEW_DEVICE_TYPES_BY_PROJECT_ID } from '../../schema/deviceTypes';
import { CREATE_VERSION, UPDATE_VERSION } from '../../schema/versions';

export default {
    namespaced: true,
    state: {
        device_types: [],
    },
    getters: {
    //Selected (current) project of the selected Business Unit
        device_types: (state) => {
            return state.device_types;
        },

        device_type_by_id: (state) => (id) => {
            return state.device_types.find(item => item.id === id);
        },

        version_by_id: (state) => (device_type_id, id) => {
            let type = state.device_types.find(item => item.id === device_type_id);
            if (!type)
                return null;

            return type.versions.find(item => item.id === id);
        }
    },
    mutations: {
        update_device_types: (state, device_types) => {
            state.device_types = device_types;
        },
        update_device_type_by_id: (state, device_type) => {
            const index = state.device_types.findIndex(item => item.id === device_type.id);
            if (index !== -1) {
                state.device_types.splice(index, 1, device_type);
            } else {
                state.device_types.push(device_type);
            }
        },
        delete_device_type_by_id: (state, device_type_id) => {
            const index = state.device_types.findIndex(item => item.id === device_type_id);
            if (index !== -1) {
                state.device_types.splice(index, 1);
            }
        },
        update_version_by_id: (state, version) => {

            let type_index = state.device_types.findIndex(item => item.id === version.device_type_id);
            if (type_index === -1)
                return null;

            let index = state.device_types[type_index].versions.findIndex(item => item.id === version.id);
            if (index !== -1) {
                state.device_types[type_index].versions.splice(index, 1, version);
            } else {
                state.device_types[type_index].versions.push(version);
            }
        },
    },
    actions: {
        load_device_types: ({ commit, rootGetters }, payload) => {
            return new Promise((resolve, reject) =>{

                rootGetters.apollo.query({
                    query: VIEW_DEVICE_TYPES_BY_PROJECT_ID,
                    variables: {
                        projectId: payload.project_id,
                        filters: { item_per_page: 1000 },
                        with: ['versions'],
                    },
                    fetchPolicy: 'no-cache',
                    notifyOnNetworkStatusChange: true
                }).then(({ data, loading }) => {
                    if (!loading && data !== undefined) {

                        commit('update_device_types', data.deviceTypesByProjectId.data);

                        rootGetters.apollo.subscribe({
                            query: SUBSCRIPTION_DEVICE_TYPES,
                            variables: {
                                projectId: payload.project_id
                            }
                        }).subscribe({
                            async next(message)  {
                                const device_type = message.data.liveDeviceType;

                                if (device_type.deleted_at && device_type.deleted_at !== '') {
                                    commit('delete_device_type_by_id', device_type.id);
                                } else
                                    commit('update_device_type_by_id', device_type);
                            },
                            error(error) {
                                console.error('error', error);
                            }
                        });

                    }
                    resolve();

                }).catch((error) => {
                    console.error(error.message);
                    reject(error);
                });
            });
        },

        delete: ({ commit, rootGetters }, payload) => {
            return new Promise((resolve, reject) =>{

                rootGetters.apollo.mutate({
                    mutation: DELETE_DEVICE_TYPE,
                    variables: {
                        id: payload.id
                    },
                    fetchPolicy: 'no-cache',
                    notifyOnNetworkStatusChange: true
                }).then(({ data, loading }) => {
                    if (!loading && data !== undefined) {

                        commit('delete_device_type_by_id', payload.id);

                        resolve();
                    }
                }).catch((error) => {
                    console.error(error.message);
                    reject(error);
                });
            });
        },

        storeOrUpdateVersion: ({ commit, rootGetters }, payload) => {
            return new Promise((resolve, reject) =>{
                let type = payload.create ? 'storeVersion' : 'updateVersion';

                rootGetters.apollo.mutate({
                    mutation: payload.create ? CREATE_VERSION : UPDATE_VERSION,
                    variables: {
                        id: !payload.create ? parseInt(payload.id) : null,
                        input: payload.version,
                    },
                })
                    .then((response) => {
                        commit('update_version_by_id', response.data[type]);
                        resolve(response.data[type]);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

    }
};
