<template>
    <div class="navbar-item has-dropdown is-hoverable" v-if="registry">
        <a class="navbar-link" >
              <span class="">
                  <span style="margin-right: 5px">{{ registry.name }}</span>
                  <fa icon="clipboard-list" fixed-width></fa>
              </span>
              <span class="icon is-small">
                  <fa icon="angle-down" fixed-width aria-hidden="true"></fa>
              </span>
        </a>
        <div class="navbar-dropdown">
              <a class="navbar-item" href="#" v-for="r in registries" :key="r.id"
                  :class="{ 'is-active': r.id === registry.id }" @click="select_registry(r.id)">
                  {{ r.name }}
                  <fa icon="exchange-alt" fixed-width></fa>
              </a>
        </div>
    </div>
</template>

<script>
export default {
    name: 'RegistrySelector',

    data: () => {
        return {
        };
    },

    computed: {
        registry() {
            return this.$store.getters['registries/registry'];
        },

        registries() {
            return this.$store.getters['registries/registries'];
        },
    },

    methods: {
        select_registry(id) {
            this.$store.commit('registries/set_registry_by_id', id);
        }
    },
};
</script>
