<template>
    <button :disabled="disabled" @click="downloadToken">
        <span class="icon is-small">
            <fa icon="cloud-download-alt" fixed-width></fa>
        </span>
        <span>Download Token file</span>
    </button>
</template>

<script>
export default {
    name: 'ProjectProvisioningFileDownloadButton',

    components: {},

    props: ['registry'],

    data() {
        return {};
    },

    computed: {
        disabled() {
            return !(this.registry.provisioning_secret && this.registry.provisioning_secret.length > 0);
        }
    },

    methods: {

        downloadToken() {

            const data = JSON.stringify({
                server_url: this.registry.cloud_provider.configuration.server_url,
                mqtt_host: this.registry.cloud_provider.configuration.mqtt_host ? this.registry.cloud_provider.configuration.mqtt_host : 'no-used',
                mqtt_port: this.registry.cloud_provider.configuration.mqtt_port ? this.registry.cloud_provider.configuration.mqtt_port : '8883',
                token: this.registry.provisioning_secret,
                project_id: this.registry.project_id,
                region_id: 1,
                registry_id: this.registry.id,
            }, undefined, 4);

            const filename = `${this.registry.name}_token.json`;

            let blob = new Blob([data], { type: 'text/json' }),
                a = document.createElement('a'),
                e = new MouseEvent('click', {
                    bubbles: true,
                    cancelable: false,
                    view: window,
                    clientX: 0,
                    clientY: 0,
                    screenX: 0,
                    screenY: 0,
                    ctrlKey: false,
                    altKey: false,
                    shiftKey: false,
                    metaKey: false,
                    button: 0,
                    relatedTarget: null
                });

            a.download = filename;
            a.href = window.URL.createObjectURL(blob);
            a.dataset.downloadurl = ['text/json', a.download, a.href].join(':');
            a.dispatchEvent(e);
        },
    }

};
</script>
