
import axios from "axios";
//import log from '../../logger';

import {reduce} from 'lodash-es'
import Configuration from "../../../../configuration";

const invitations_route = Configuration.VUE_APP_OAUTH_SERVER_URL + '/api/v1/invitations';
const container_invitations_route =
          Configuration.VUE_APP_OAUTH_SERVER_URL +
          '/api/v1/containers/{container}/invitations?container_item_id={container_item_id}';

export default {

    namespaced: true,

    state: {
        //invitations: [],
    },

    getters: {
        // invitations(state){
        //     return state.invitations;
        // },

        authorization_token (state, getters, rootState, rootGetters) {
            return rootGetters['oauth/authorization_token'];
        },
    },

    mutations: {
        // set_invitations(state, invitations) {
        //     state.invitations = invitations;
        // },
    },

    actions: {

        getInvitations(context, {options, role_ids})
        {
            const params = {
                options: options
            }

            let route = container_invitations_route
                .replace('{container}', options.container)
                .replace('{container_item_id}', options.container_item_id);

            return new Promise((resolve, reject) => {
                axios.get( route, {
                    headers: {
                        'Authorization': context.getters.authorization_token,
                    },
                    params: params,
                } )
                     .then( function (response) {
                         // context.commit('set_invitations',
                         let invitations = reduce( response.data, (result, value /*, key*/) => {
                                  if (role_ids.indexOf( value.role_id ) >= 0) {
                                    result.push( value )
                                  }
                                  return result
                                }, [] );
                             // );
                         resolve(invitations);
                     } )
                     .catch( function (error) {
                         reject(error);
                     } );
            });
        },

        postInvitations(context, data)
        {
            return new Promise((resolve, reject) => {
                axios
                    .post( invitations_route, data, {
                        headers: {
                            'Authorization': context.getters.authorization_token,
                        }
                    })
                    .then( function (response) {
                        resolve(response);
                    } )
                    .catch( function (error) {
                        reject(error);
                    } );
            });
        },

        putInvitations(context, {invitation_id, data})
        {
            return new Promise((resolve, reject) => {
                axios
                    .put( invitations_route + '/' + invitation_id, data, {
                        headers: {
                            'Authorization': context.getters.authorization_token,
                        }
                    })
                    .then( function (response) {
                        resolve(response);
                    } )
                    .catch( function (error) {
                        reject(error);
                    } );
            });
        },

        deleteInvitations(context, {invitation_id, data})
        {
            return new Promise((resolve, reject) => {
                axios
                    .delete( invitations_route + '/' + invitation_id, {
                        data: data,
                        headers: {
                            'Authorization': context.getters.authorization_token,
                        }
                    })
                    .then( function (response) {
                        resolve(response);
                    } )
                    .catch( function (error) {
                        reject(error);
                    } );
            });
        }

    }
}
