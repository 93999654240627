<template>
    <div class="modal" v-bind:class="{ 'is-active': is_active }">
        <div class="modal-background"></div>
        <div class="modal-content">
            <progress class="progress is-large" max="100"></progress>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        show: {
            type: Boolean,
            required: true
        }
    },
    data: () => {
        return {
            is_active: false,
            timeout: null,
        };
    },
    watch: {
        show: {
            handler(show) {
                clearTimeout(this.timeout);
                if (show === true) {
                    this.timeout = setTimeout(this.make_visible_is_necessary, 250);
                } else {
                    this.is_active = false;
                }
            },
            immediate: true
        }
    },
    methods: {
        make_visible_is_necessary() {
            if (this.show === true) {
                this.is_active = true;
            }
        }
    }
};
</script>
