import gql from 'graphql-tag';
export const SUBSCRIPTION_PUB_SUB_TOPICS = gql`
    subscription livePubSubTopics($projectId: Int64!) {
        livePubSubTopics(projectId: $projectId) {
            id
            name
            is_default
            default_type
            updated_at
            status
            inbound
        }
    }
`;

export const CREATE_PUB_SUB_TOPIC = gql`
    mutation storePubSubTopic($projectId: Int64!, $input: PubSubTopicInput!) {
        storePubSubTopic(projectId: $projectId, input: $input) {
            id
            name
            is_default
            default_type
            updated_at
            status
            inbound
        }
    }
`;

export const UPDATE_PUB_SUB_TOPIC = gql`
    mutation updatePubSubTopic($id: Int64!, $projectId: Int64!, $input: PubSubTopicInput!) {
        updatePubSubTopic(id: $id, projectId: $projectId, input: $input) {
            id
            name
            is_default
            default_type
            updated_at
            status
            inbound
        }
    }
`;
