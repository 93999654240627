import {
    CREATE_COMMUNICATION_METHOD,
    UPDATE_COMMUNICATION_METHOD,
    CREATE_PROTOCOL,
    UPDATE_PROTOCOL,
    VIEW_COMMUNICATIONS,
    VIEW_PROTOCOLS, VIEW_CLOUD_PROVIDERS, CREATE_CLOUD_PROVIDER, UPDATE_CLOUD_PROVIDER
} from '../../schema/utilities';

export default {
    namespaced: true,
    state: {
        protocols: [],
        communication_methods: [],
        cloud_providers: [],
        adapterTypes: [],
    },
    getters: {
        protocols: (state) => {
            return state.protocols;
        },
        protocol_by_id: (state) => (id) => {
            return state.protocols.find(item => item.id === id);
        },

        communication_methods: (state) => {
            return state.communication_methods;
        },
        communication_method_by_id: (state) => (id) => {
            return state.communication_methods.find(item => item.id === id);
        },

        cloud_providers: (state) => {
            return state.cloud_providers;
        },
        cloud_provider_by_id: (state) => (id) => {
            return state.cloud_providers.find(item => item.id === id);
        },

        adapterTypes: (state) => {
            return state.adapterTypes;
        },
        adapterTypeDefault: (state) => {
            return state.adapterTypes.at(0);
        },
    },
    mutations: {
        update_protocols: (state, protocols) => {
            state.protocols = protocols;
        },
        update_protocol_by_id: (state, obj) => {
            const index = state.protocols.findIndex(item => item.id === obj.id);
            if (index !== -1) {
                state.protocols.splice(index, 1, obj);
            } else {
                state.protocols.push(obj);
            }
        },

        update_communication_methods: (state, communication_methods) => {
            state.communication_methods = communication_methods;
        },
        update_communication_method_by_id: (state, obj) => {
            const index = state.communication_methods.findIndex(item => item.id === obj.id);
            if (index !== -1) {
                state.communication_methods.splice(index, 1, obj);
            } else {
                state.communication_methods.push(obj);
            }
        },

        update_cloud_providers: (state, cloud_providers) => {
            state.cloud_providers = cloud_providers;
        },
        update_cloud_provider_by_id: (state, obj) => {
            const index = state.cloud_providers.findIndex(item => item.id === obj.id);
            if (index !== -1) {
                state.cloud_providers.splice(index, 1, obj);
            } else {
                state.cloud_providers.push(obj);
            }
        },

        updateAdapterTypes: (state, adapterTypes) => {
            state.adapterTypes = adapterTypes;
        }
    },
    actions: {
        load_protocols: ({ commit, rootGetters }) => {
            return new Promise((resolve, reject) => {
                rootGetters.apollo.query({
                    query: VIEW_PROTOCOLS,
                    variables: {
                        filters: { item_per_page: 1000 },
                    },
                    fetchPolicy: 'no-cache',
                    notifyOnNetworkStatusChange: true
                }).then(response => {
                    commit('update_protocols', response.data.protocols.data);
                    resolve(response.data.protocols);
                }).catch(error => {
                    reject(error);
                });
            });

        },
        load_comm_methods: ({ commit, rootGetters }) => {
            return new Promise((resolve, reject) => {
                rootGetters.apollo.query({
                    query: VIEW_COMMUNICATIONS,
                    variables: {
                        filters: { item_per_page: 1000 },
                    },
                    fetchPolicy: 'no-cache',
                    notifyOnNetworkStatusChange: true
                }).then(response => {
                    commit('update_communication_methods', response.data.communication_methods.data);
                    resolve(response.data.protocols);
                }).catch(error => {
                    reject(error);
                });
            });

        },

        load_cloud_provider: ({ commit, rootGetters }) => {
            return new Promise((resolve, reject) => {
                rootGetters.apollo.query({
                    query: VIEW_CLOUD_PROVIDERS,
                    variables: {
                        filters: { item_per_page: 1000 },
                    },
                    fetchPolicy: 'no-cache',
                    notifyOnNetworkStatusChange: true
                }).then(response => {
                    commit('update_cloud_providers', response.data.cloudProviders.data);
                    resolve(response.data.cloudProviders);
                }).catch(error => {
                    reject(error);
                });
            });

        },

        loadAdapterTypes: ({ commit }) => {
            return new Promise((resolve, /* reject */) => {
                let data = [
                    {
                        id: 1,
                        name: 'MQTT',
                        fields: {
                            host: {
                                type: 'string',
                                label: 'Hostname',
                                required: true,
                            },
                            port: {
                                type: 'integer',
                                label: 'Port',
                                required: true,
                                min: 1,
                                max: 65535,
                            },
                            clientid: {
                                type: 'string',
                                label: 'Client ID',
                                required: true,
                            },
                            username: {
                                type: 'string',
                                label: 'Username',
                            },
                            password: {
                                type: 'string',
                                label: 'Password',
                                encrypted: true,
                            },
                        },
                    },
                    {
                        id: 2,
                        name: 'MQTTS',
                        fields: {
                            host: {
                                type: 'string',
                                label: 'Hostname',
                                required: true,
                            },
                            port: {
                                type: 'integer',
                                label: 'Port',
                                required: true,
                                min: 1,
                                max: 65535,
                            },
                            clientid: {
                                type: 'string',
                                label: 'Client ID',
                                required: true,
                            },
                            username: {
                                type: 'string',
                                label: 'Username',
                            },
                            password: {
                                type: 'string',
                                label: 'Password',
                                encrypted: true,
                            },
                        },
                    },
                ];
                commit('updateAdapterTypes', data);
                resolve(data);
            });
        },

        storeOrUpdateProtocol: ({ commit, rootGetters }, payload) => {
            return new Promise((resolve, reject) => {
                let type = payload.create ? 'storeProtocol' : 'updateProtocol';

                rootGetters.apollo.mutate({
                    mutation: payload.create ? CREATE_PROTOCOL : UPDATE_PROTOCOL,
                    variables: {
                        id: !payload.create ? parseInt(payload.id) : null,
                        input: payload.protocol,
                    },
                })
                    .then((response) => {
                        commit('update_protocol_by_id', response.data[type]);
                        resolve(response.data[type]);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        storeOrUpdateCommMethod: ({ commit, rootGetters }, payload) => {
            return new Promise((resolve, reject) => {
                let type = payload.create ? 'storeCommunicationMethod' : 'updateCommunicationMethod';

                rootGetters.apollo.mutate({
                    mutation: payload.create ? CREATE_COMMUNICATION_METHOD : UPDATE_COMMUNICATION_METHOD,
                    variables: {
                        id: !payload.create ? parseInt(payload.id) : null,
                        input: payload.communication_method,
                    },
                })
                    .then((response) => {
                        commit('update_communication_method_by_id', response.data[type]);
                        resolve(response.data[type]);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        storeOrUpdateCloudProvider: ({ commit, rootGetters }, payload) => {
            return new Promise((resolve, reject) => {
                let type = payload.create ? 'storeCloudProvider' : 'updateCloudProvider';

                rootGetters.apollo.mutate({
                    mutation: payload.create ? CREATE_CLOUD_PROVIDER : UPDATE_CLOUD_PROVIDER,
                    variables: {
                        id: !payload.create ? parseInt(payload.id) : null,
                        input: payload.cloud_provider,
                    },
                })
                    .then((response) => {
                        commit('update_cloud_provider_by_id', response.data[type]);
                        resolve(response.data[type]);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
    }
};
