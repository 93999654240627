import gql from 'graphql-tag';

export const VIEW_DEVICES = gql`
    query viewDevices($projectId: Int64!, $filters: FiltersInput!) {
        devices(projectId: $projectId, filters: $filters) {
            data {
                id
                project_id
                name
                serial_number
                up_time
                created_at
                registry_id
                device_type {
                    id
                    name
                    icon
                }
            }
            pagination {
                total
                last_page
            }
        }
    }
`;
export const SEARCH_DEVICES = gql`
    query searchDevices($projectId: Int64!, $filters: FiltersInput!) {
        devices(projectId: $projectId, filters: $filters) {
            data {
                id
                project_id
                name
                serial_number
                deleted_at
                device_type {
                    id
                    name
                    icon
                }
                registry {
                    name
                }
            }
            pagination {
                total
                last_page
            }
        }
    }
`;

export const UPDATE_DEVICE = gql`
    mutation updateDevice($projectId: Int64!, $deviceId: Int64!, $input: DeviceUpdateInput!) {
        updateDevice(projectId: $projectId, deviceId: $deviceId, input: $input) {
            id
            project_id
            name
            serial_number
            wait_reboot
            undeploy_lora_app_key {
                created_at
                parameters
            }
            last_heartbeat_at
            up_time
            device_type {
                id
                name
                is_accessible
                icon
            }
            protocols {
                id
                name
            }
            communication_methods {
                id
                name
            }
            current_version {
                id
                name
                revision
                identifier
            }
            created_at
            updated_at
            deleted_at
            meta_data
            configuration
            undeploy_configuration
            reachable_by {
                id
                name
                serial_number
                pivot {
                    is_connexion_lost
                }
            }
            registry_id
            registry {
                project_id
                name
            }
        }
    }
`;

export const CREATE_DEVICE = gql`
    mutation createDevice($projectId: Int64!, $device: DeviceInput!) {
        storeDevice(projectId: $projectId, input: $device) {
            id
            project_id
            name
            serial_number
            wait_reboot
            undeploy_lora_app_key {
                created_at
                parameters
            }
            last_heartbeat_at
            up_time
            device_type {
                id
                name
                is_accessible
                icon
            }
            protocols {
                id
                name
            }
            communication_methods {
                id
                name
            }
            current_version {
                id
                name
                revision
                identifier
            }
            created_at
            updated_at
            deleted_at
            meta_data
            configuration
            undeploy_configuration
            reachable_by {
                id
                name
                serial_number
                pivot {
                    is_connexion_lost
                }
            }
            registry_id
            registry {
                project_id
                name
            }
        }
    }
`;

export const DELETE_DEVICE = gql`
    mutation deleteDevice($projectId: Int64!, $deviceId: Int64!) {
        deleteDevice(projectId: $projectId, deviceId: $deviceId) {
            id
            project_id
            registry_id
            name
            serial_number
            wait_reboot
            undeploy_lora_app_key {
                created_at
                parameters
            }
            last_heartbeat_at
            up_time
            device_type {
                id
                name
                is_accessible
                icon
            }
            protocols {
                id
                name
            }
            communication_methods {
                id
                name
            }
            current_version {
                id
                name
                revision
                identifier
            }
            created_at
            updated_at
            deleted_at
            meta_data
            configuration
            undeploy_configuration
            reachable_by {
                id
                name
                serial_number
                pivot {
                    is_connexion_lost
                }
            }
            registry {
                project_id
                name
            }
        }
    }
`;

export const SUBSCRIPTION_DEVICE = gql`
    subscription liveDevice($deviceId: Int64!, $projectId: Int64!) {
        liveDevice(deviceId: $deviceId, projectId: $projectId) {
            id
            project_id
            registry_id
            name
            serial_number
            wait_reboot
            undeploy_lora_app_key {
                created_at
                parameters
            }
            last_heartbeat_at
            up_time
            device_type {
                id
                name
                is_accessible
                icon
            }
            protocols {
                id
                name
            }
            communication_methods {
                id
                name
            }
            current_version {
                id
                name
                revision
                identifier
            }
            created_at
            updated_at
            deleted_at
            meta_data
            configuration
            undeploy_configuration
            reachable_by {
                id
                name
                serial_number
                pivot {
                    is_connexion_lost
                }
            }
            registry {
                project_id
                name
            }
        }
    }
`;

export const VIEW_DEVICE_AUTHENTICATION = gql`
    query viewDeviceAuthentication($projectId: Int64!, $deviceId: Int64!) {
        deviceAuthentication(projectId: $projectId, deviceId: $deviceId) {
            secret
            algorithm
        }
    }
`;
export const UPDATE_DEVICE_AUTHENTICATION = gql`
    mutation storeDeviceAuthentication($projectId: Int64!, $deviceId: Int64!, $input: DeviceAuthenticationInput!) {
        storeDeviceAuthentication(projectId: $projectId, deviceId: $deviceId, input: $input) {
            secret
            algorithm
        }
    }
`;
