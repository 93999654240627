<template>
    <footer class="card-footer mb-4">
        <a
            href="#"
            class="card-footer-item"
            v-if="!is_pending"
            @click="retry_command"
            >Retry</a
        >
        <a
            href="#"
            class="card-footer-item"
            v-if="is_pending"
            @click="cancel_command"
            >Cancel</a
        >
    </footer>
</template>

<script>
import { parseFundamentumError } from '@/utils/fundamentumErrors';

export default {
    name: 'device-action-card-footer',
    props: {
        project_id: {
            required: true,
        },
        device_action_id: {
            required: true,
        },
        is_pending: {
            required: true,
        },
    },
    data: () => {
        return {};
    },
    methods: {
        cancel_command() {
            this.update_device_command('cancel');
        },

        retry_command() {
            this.update_device_command('retry');
        },

        update_device_command(status) {
            this.$store
                .dispatch('deviceActions/update', {
                    project_id: this.project_id,
                    device_action_id: this.device_action_id,
                    status: status,
                })
                .then(() => {
                    this.$toast.success(`Device Action Updated`);
                })
                .catch((error) => {
                    let [, last_message] = parseFundamentumError(error);

                    this.$toast.error(last_message);
                })
                .finally(() => {
                    //this.loading = false;
                });
        },
    },
};
</script>
