<template>
    <section class="hero is-danger">
        <div class="hero-body">
            <div class="container">
                <h1 class="title">
                    Access denied
                </h1>
                <h2 class="subtitle">{{ error_message }}</h2>
            </div>
        </div>
    </section>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'

export default {
    name: 'NoAccess',
    components: {},
    computed: {
        error_message() {
            return this.$store.getters['error_message'];
        }
    },
    beforeRouteEnter(to, from, next) {
        next(//vm => {
            //vm.$oauthInitSession();
            //}
        );
    }
};
</script>
