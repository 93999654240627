import {
    CREATE_RECEIVER,
    DELETE_RECEIVER,
    UPDATE_RECEIVER,
    VIEW_RECEIVERS,
    VIEW_RECEIVER_STATUS,
} from '../../schema/receivers';
import Configuration from '../../configuration';

export default {
    namespaced: true,
    state: {
        connectors: [],
        activated: Configuration.VUE_APP_FEATURES_CONNECTORS_ACTIVATED === 'true',
    },
    getters: {
        connectors: (state) => {
            return state.connectors;
        },
        connectorById: (state) => (id) => {
            return state.connectors.find(x => x.id === id);
        },
        activated: (state) => {
            return state.activated;
        },
    },
    mutations: {
        updateConnectors: (state, connectors) => {
            state.connectors = connectors;
        },
        updateConnectorById: (state, obj) => {
            const index = state.connectors.findIndex(x => x.id === obj.id);
            if (index !== -1) {
                state.connectors.splice(index, 1, obj);
            } else {
                state.connectors.push(obj);
            }
        },
        deleteConnectorById: (state, id) => {
            const index = state.connectors.findIndex(x => x.id === id);
            if (index !== -1) {
                state.connectors.splice(index, 1);
            }
        },
    },
    actions: {
        loadConnectors: ({ commit, rootGetters }, payload) => {
            return new Promise((resolve, reject) => {
                rootGetters.apollo.query({
                    query: VIEW_RECEIVERS,
                    variables: {
                        projectId: payload.projectId,
                        filters: { 'item_per_page': 1000, 'order_by': { 'id': 'asc' } },
                    },
                    fetchPolicy: 'no-cache',
                    notifyOnNetworkStatusChange: true
                }).then(response => {
                    commit('updateConnectors', response.data.receivers.data);
                    resolve(response.data.receivers);
                }).catch(error => {
                    reject(error);
                });
            });

        },
        storeOrUpdateConnector: ({ commit, rootGetters }, payload) => {
            return new Promise((resolve, reject) => {
                let type = payload.create ? 'storeReceiver' : 'updateReceiver';

                rootGetters.apollo.mutate({
                    mutation: payload.create ? CREATE_RECEIVER : UPDATE_RECEIVER,
                    variables: {
                        id: !payload.create ? parseInt(payload.id) : null,
                        projectId: payload.projectId,
                        input: payload.connector,
                    },
                })
                    .then((response) => {
                        commit('updateConnectorById', response.data[type]);
                        resolve(response.data[type]);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        delete: ({ commit, rootGetters }, payload) => {
            return new Promise((resolve, reject) => {

                rootGetters.apollo.mutate({
                    mutation: DELETE_RECEIVER,
                    variables: {
                        projectId: payload.projectId,
                        id: payload.id
                    },
                    fetchPolicy: 'no-cache',
                    notifyOnNetworkStatusChange: true
                }).then(({ data, loading }) => {
                    if (!loading && data !== undefined) {

                        commit('deleteConnectorById', payload.id);

                        resolve();
                    }
                }).catch((error) => {
                    console.error(error.message);
                    reject(error);
                });
            });
        },
        loadConnectorStatus: ({ rootGetters }, payload) => {
            return new Promise((resolve, reject) => {
                rootGetters.apollo.query({
                    query: VIEW_RECEIVER_STATUS,
                    variables: {
                        projectId: payload.projectId,
                        receiverId: payload.id,
                    },
                    fetchPolicy: 'no-cache',
                    notifyOnNetworkStatusChange: true
                }).then(response => {
                    resolve(response.data.receiverStatus);
                }).catch(error => {
                    reject(error);
                });
            });
        },
    }
};
