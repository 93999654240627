// https://github.com/howtographql/vue-apollo/tree/master/src
// https://apollo.vuejs.org/guide/apollo/queries.html#reactive-parameters

import gql from 'graphql-tag';

export const GET_PROJECT = gql`
    query getProject($id: Int64!) {
        project(id: $id) {
            id
            name
            registries {
                id
                project_id
                name
                cuid
                configuration
                provisioning_secret
                provisioning_end_at
                provisioning_start_at
                cloud_provider_id
                device_types {
                    id
                    name
                    icon
                    asset_type_id
                    project_id
                    meta_data
                    default_configuration
                    should_self_provision_has_mps
                    is_accessible
                    is_gateway
                    is_lorawan
                    updated_at
                    deleted_at
                    pivot {
                      provisioning_quantity
                      device_count
                    }
                }
                cloud_provider {
                    name
                    configuration
                }
                topics {
                    id
                    pub_sub_topic_id
                    name
                    type
                    sub_type_name
                    configuration
                }
                updated_at
                deleted_at
            }
        }
    }
`;

export const SYNC_DEVICE_TYPE = gql`
    mutation syncDeviceType($registryId: Int64!, $sync_devices: SyncTypeInput!) {
        syncDeviceType(registryId: $registryId, sync_devices: $sync_devices) {
            id
            name
            asset_type_id
            pivot {
                provisioning_quantity
                device_count
            }
        }
    }
`;
