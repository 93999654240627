<template>
    <article class="message is-darker" v-if="rowData">
        <div class="message-body">
            <device-action-card-content
                :device_id="device_id"
                :device_action="rowData"
                :progression="rowData.progression"
            />
            <device-action-card-footer
                :project_id="project_id"
                :device_action_id="rowData.id"
                :is_pending="isPending"
            >
            </device-action-card-footer>
        </div>
    </article>
</template>

<script>
import DeviceActionCardContent from '@/components/DeviceActionCardContent.vue';
import DeviceActionCardFooter from '@/components/DeviceActionCardFooter.vue';
import DevicesCommandsActionsMixin from '@/mixins/devices-commands/DevicesCommandsActionsMixin';

export default {
    name: 'device-action-detail-row',
    mixins: [DevicesCommandsActionsMixin],
    components: { DeviceActionCardContent, DeviceActionCardFooter },
    props: {
        rowData: {
            type: Object,
            required: true,
        },
        rowIndex: {
            type: Number,
        },
        options: {
            type: Object,
        },
    },

    computed: {
        project_id() {
            return this.$store.getters['businessUnit/project'].id;
        },
        device_id() {
            return this.$store.getters['devices/getDevice'].id;
        },
        isPending() {
            return this.rowData.status === 'pending';
        },
    },

    methods: {},
};
</script>
