let OAuthPlugin = {};

import {differenceInMilliseconds, isAfter, subMinutes} from "date-fns";

import log from '../logger';

import OAuthStore from "./store";

import invitation_en from './locale/invitations/en.json'
import invitation_fr from './locale/invitations/fr.json'

OAuthPlugin.install = function (Vue, options) {

    OAuthPlugin.addRoute(options.router);
    options.store.registerModule('oauth', OAuthStore);

    //Vue.component('oauth-callback', require('./components/LoadingBar.vue'));

    Vue.prototype.$log = function ($stuff) {
        // eslint-disable-next-line
        console.log($stuff);
    };

    Vue.i18n.add("en", {"oauth": { "invitation": invitation_en } });
    Vue.i18n.add("fr", {"oauth": { "invitation": invitation_fr } });

    Vue.prototype.$oauthInitSession = function () {
        let that = this;

        return new Promise((resolve) => {

            let isValidToken = this.$store.getters["oauth/isOAuthTokenValid"]();

            if (!isValidToken) {
                return that.$router.push({ name: "oauth-login"});
            }

            return resolve();
        });

    };

    Vue.prototype.$oauthRefreshToken = function () {

        /*axios.post(this.$oauthRefreshRoute(), {
            grant_type: "refresh_token",
            refresh_token: "",
            client_id: "",
            client_secret: "",
            scope: ""
        })
            .then(response => {

                context.commit('set_options', response.data.data.user_options);
                //context.commit('set_base_config', response.data.data.config_options);

                resolve(response.data.data.new_notification);ù
            })
            .catch(response => {
                console.log(response);
                reject(); //TODO: think about somwthing....
            });*/

    };

    Vue.prototype.$oauthCheckSession = function () {
        let that = this;

        return new Promise((resolve) => {

            log("$oauthCheckSession");
            let tokenExpiryDate = this.$store.state.oauth.tokensExpiry;

            if (!tokenExpiryDate || tokenExpiryDate === "Invalid Date") {
                // eslint-disable-next-line
                console.log("No token expiry date. user probably never logged in");
                return resolve(false);
            }

            //If the token has expired or will expire in the next 10 minutes
            let oneMinutesBeforeExpiry = subMinutes(tokenExpiryDate, 1);
            const now = new Date();

            //If the token has expired or will expire in the next 1 minutes
            if (isAfter(now, oneMinutesBeforeExpiry)) {
                that.$log("Token expired/will expire in the next 1 minutes");
                return resolve(false);
            }

            setTimeout(function(){
                that.$store.commit('oauth/clear_token');
            }, differenceInMilliseconds(oneMinutesBeforeExpiry, now));

            this.$log("Token OK");

            return resolve(true);
        });

    };


    Vue.prototype.$oauthAuthorizeRoute = function () {
        return process.env.VUE_APP_OAUTH_SERVER_URL + process.env.VUE_APP_OAUTH_AUTHORIZE_ROUTE;
    };

    /*Vue.prototype.$oauthRefreshRoute = function () {
        return process.env.VUE_APP_OAUTH_SERVER_URL + process.env.VUE_APP_OAUTH_REFRESH_ROUTE;
    };*/

};

OAuthPlugin.addRoute = function (Router) {

    Router.addRoutes([
        {
            path: '/oauth/login',
            name: 'oauth-login',
            component: () => import('./views/OAuthLogin.vue')
        },
        {
            path: '/oauth/user',
            name: 'oauth-user',
            component: () => import('./views/OAuthUserAccount.vue')
        },
        {
            path: '/oauth/logout',
            name: 'oauth-logout',
            component: () => import('./views/OAuthLogout.vue')
        },
        {
            path: '/oauth/callback*',
            name: 'oauth-callback',
            component: () => import('./views/OAuthCallBack.vue')
        },
        {
            path: '/oauth/project-selector',
            name: 'oauth-project-selector',
            component: () => import('./views/OAuthProjectSelector.vue')
        },
        {
            path: '/oauth/project-selector/callback*',
            name: 'oauth-project-selector-callback',
            component: () => import('./views/OAuthProjectSelectorCallBack.vue')
        },
    ]);
};

export default OAuthPlugin;
