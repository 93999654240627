<template>
    <multiselect v-model="selection" placeholder="Type to search devices" open-direction="bottom" :options="options"
        label="name" track-by="id" select-label="" :multiple="true" :searchable="true" :loading="loading"
        :clear-on-select="false" :close-on-select="false" :options-limit="300" :limit="10" :limit-text="limitText"
        :max-height="600" :show-no-results="false" :show-no-options="false" :internal-search="false" :hide-selected="true"
        :block-keys="['Delete']" @search-change="onTriggerSearch"></multiselect>
</template>

<script>
import Multiselect from 'vue-multiselect';
import { SEARCH_DEVICES } from '@/schema/devices';
export default {
    name: 'RegistryDevicesMultiSelect',
    components: {
        Multiselect
    },
    props: {
        value: {
            type: Array,
            required: true,
        },
        registry_id: {
            type: Number,
            required: true,
        },
    },
    data: () => {
        return {
            searchDelay: null,
            search: '',
            options: [],
            selection: [],
            loading: false
        };
    },
    watch: {
        value: {
            handler(value) {
                this.selection = value;
            },
            immediate: true,
        },
        selection: {
            handler(selection) {
                this.$emit('input', selection);
            }
        }
    },
    computed: {
        project_id() {
            return this.$store.getters['businessUnit/project'] ?
                this.$store.getters['businessUnit/project'].id : 0;
        }
    },
    methods: {
        limitText(count) {
            return `and ${count} other devices`;
        },
        onTriggerSearch(search) {
            this.search = search;
            clearInterval(this.searchDelay);

            if (this.search.trim().length > 0) {
                this.searchDelay = setInterval(() => {
                    this.onPerformSearch();
                }, 200);
            } else {
                this.options = [];
            }
        },
        onPerformSearch() {
            clearInterval(this.searchDelay);

            this.$apollo.query({
                query: SEARCH_DEVICES,
                variables: {
                    projectId: this.project_id,
                    filters: {
                        wheres: [
                            {
                                compare_to: this.registry_id,
                                compare_with: '=',
                                name: 'registry_id'
                            },
                        ],
                        or_wheres: [
                            {
                                compare_to: '%25' + this.search + '%25',
                                compare_with: 'LIKE',
                                name: 'name',
                            },
                            {
                                compare_to: '%25' + this.search + '%25',
                                compare_with: 'LIKE',
                                name: 'serial_number',
                            },
                        ],
                    }
                },
            }).then((result) => {
                this.options = result.data.devices.data.map((device) => {
                    return {
                        id: device.id,
                        name: device.name
                    };
                });
            }).catch((error) => {
                this.$toast.error(error.message);
                this.options = [];
            }).finally(() => {
                this.loading = false;
            });
        }
    },
};
</script>

<style lang="scss" scoped>
.multiselect::v-deep {
    .multiselect__content-wrapper {
        box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.5) !important;
    }
}
</style>
