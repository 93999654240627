import { setContext } from 'apollo-link-context';

export const gatewayConnector = (store, getterTokenName) => {
    return {
        authMiddleware: setContext(async () => {
            if (!store.getters[getterTokenName]) return {};

            return {
                headers: {
                    authorization: store.getters[getterTokenName] || null,
                    role: 'super_admin',
                },
            };
        }),
    };
};

export const authTokenWS = (store, getterTokenName) => {
    return function () {
        return {
            Authorization: store.getters[getterTokenName] || null,
        };
    };
};
