import gql from 'graphql-tag';

export const LIST_DEVICE_COMMANDS = gql`
    query deviceCommands($deviceId: Int64!, $projectId: Int64!, $filters: FiltersInput!) {
        deviceCommands(deviceId: $deviceId, projectId: $projectId, filters: $filters) {
            data {
                id
                command_id
                device_id
                parameters
                message
                sent_at
                started_at
                done_at
                status
                log
                created_at
                updated_at
                deleted_at
                command {
                    name
                    short_name
                }
            }
            pagination {
                total
                last_page
            }
        }
    }
`;

export const SUBSCRIBE_DEVICE_COMMANDS = gql`
    subscription liveDeviceCommands($deviceId: Int64!, $projectId: Int64!) {
        liveDeviceCommands(deviceId: $deviceId, projectId: $projectId) {
            id
            command_id
            device_id
            parameters
            message
            sent_at
            started_at
            done_at
            status
            log
            callback_url
            created_at
            updated_at
            deleted_at
            command {
                name
                short_name
            }
        }
    }
`;

export const CREATE_COMMAND = gql`
    query createCommand($shortName: String!, $deviceId: Int64!) {
        createCommand(shortName: $shortName, deviceId: $deviceId) {
            device_id
            command {
                id
                name
                short_name
                description
                default_parameters
            }
            versions {
                id
                identifier
                name
                revision
            }
            gateways {
                id
                name
            }
        }
    }
`;

export const UPDATE_DEVICE_COMMAND_BATCH = gql`
  mutation updateDeviceCommandBatch($batch_id: String!, $status:String!, $input: BatchCommandUpdateInput!) {
    updateBatchCommandByStatus(batchId: $batch_id, status: $status, input: $input)
  }
`;

export const DELETE_DEVICE_COMMAND_BATCH = gql`
  mutation deleteDeviceCommandBatch($batch_id: String!, $status:String!) {
    deleteBatchCommandByStatus(batchId: $batch_id, status: $status)
  }
`;

export const UPDATE_DEVICE_COMMAND = gql`
    mutation updateDeviceCommand($deviceId: Int64!, $projectId: Int64!, $shortName: String!, $input: UpdateDeviceCommandInput!) {
        updateDeviceCommand(deviceId: $deviceId, projectId: $projectId, shortName: $shortName, input: $input)
    }
`;
export const DELETE_DEVICE_COMMAND = gql`
    mutation deleteDeviceCommand($deviceId: Int64!, $projectId: Int64!, $shortName: String!, $input: UpdateDeviceCommandInput!) {
        deleteDeviceCommand(deviceId: $deviceId, projectId: $projectId, shortName: $shortName, input: $input)
    }
`;

export const STORE_COMMANDS = gql`
    mutation storeDeviceCommand($deviceId: Int64!, $projectId: Int64!, $shortName: String!, $input: DeviceCommandInput!) {
        storeDeviceCommand(deviceId: $deviceId, projectId: $projectId, shortName: $shortName, input: $input) {
            id
        }
    }
`;

export const STORE_BATCH_COMMANDS = gql`
    mutation storeBatchCommand($registryId: Int64!, $projectId: Int64!, $shortName: String!, $input: DeviceCommandBatchInput!) {
        storeBatchCommand(registryId: $registryId, projectId: $projectId, shortName: $shortName, input: $input)
    }
`;

// export const STORE_BATCH_COMMANDS_FOR_REGISTRY_BY_DEVICE_LIST = gql`
//     mutation storeBatchCommandForRegistryByDeviceList($registry_id: Int64!, $command_name: String!, $input: DeviceCommandBatchByDeviceListInput!) {
//         storeBatchCommandForRegistryByDeviceList(registryId: $registry_id, command_name: $command_name, input: $input)
//     }
// `

export const VIEW_BATCH_COMMANDS_REPORT = gql`
    query batchCommandReport($batchId: String!) {
        batchCommandReport(batchId: $batchId) {
            count_by_status
        }
    }
`;

export const VIEW_BATCH_COMMANDS = gql`
    query batchCommand($batchId: String!, $filters: FiltersInput!) {
        batchCommand(batchId: $batchId, filters: $filters) {
            data {
                id
                command_id
                device_id
                device {
                  serial_number
                }
                command {
                  short_name
                }
                parameters
                message
                sent_at
                started_at
                status
            }
            pagination {
                total
                last_page
            }
        }
    }
`;

export const BATCH_COMMANDS_BY_CONTAINER = gql`
    query batchCommands($registryId: Int64!) {
        batchCommands(registryId: $registryId) {
            data {
                batch_id
                last_update
                commands_count
                command {
                    name
                }
            }
            pagination {
                total
                last_page
            }
        }
    }
`;
