
import { STORE_BATCH_COMMANDS } from '../../../schema/commands';

export default {
    namespaced: true,
    state: {
        loading: true,
    },
    getters: {
        loading: state => state.loading,
    },
    mutations: {
        loading: (state, loading) => {
            state.loading = loading;
        },
    },
    actions: {
        store_batch_commands: ({ commit, rootGetters }, variables) => {
            return new Promise((resolve, reject) => {
                rootGetters.apollo.mutate({
                    mutation: STORE_BATCH_COMMANDS,
                    variables
                }).then((data) => {
                    resolve(data);
                }).catch((error) => {
                    reject(error.message);
                }).finally(() => {
                    commit('loading', false);
                });
            });
        }
    }
};
