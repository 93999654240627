import gql from 'graphql-tag';

export const SUBSCRIPTION_DEVICE_ACTIONS = gql`
    subscription liveDeviceAction($projectId: Int64!, $deviceId: Int64!) {
        liveDeviceAction(projectId: $projectId, deviceId: $deviceId) {
            id
            action_id
            action {
                id
                action_type_id
                action_type {
                    name
                    type
                }
                payload
                is_scheduled
                activate_at
                end_at
                cron
                progression
            }
            device_id
            device {
                is_accessible
                reachable_by
            }
            status
            progression
            created_at
            sent_at
            started_at
            done_at
            sending_attempt_count
            updated_at
            deleted_at
        }
    }
`;

export const LIST_DEVICE_ACTIONS = gql`
    query deviceActions(
        $deviceId: Int64!
        $projectId: Int64!
        $filters: FiltersInput!
    ) {
        deviceActions(
            deviceId: $deviceId
            projectId: $projectId
            filters: $filters
        ) {
            data {
                id
                action_id
                action {
                    id
                    action_type_id
                    action_type {
                        name
                        type
                    }
                    payload
                    is_scheduled
                    activate_at
                    end_at
                    cron
                    progression
                }
                device_id
                device {
                    is_accessible
                    reachable_by
                }
                status
                progression
                created_at
                sent_at
                started_at
                done_at
                sending_attempt_count
                updated_at
                deleted_at
            }
            pagination {
                total
                last_page
            }
        }
    }
`;

export const UPDATE_DEVICE_ACTION = gql`
    mutation updateDeviceAction(
        $projectId: Int64!
        $id: Int64!
        $input: DeviceActionInput!
    ) {
        updateDeviceAction(projectId: $projectId, id: $id, input: $input) {
            id
            action_id
            action {
                id
                action_type_id
                action_type {
                    name
                    type
                }
                payload
                is_scheduled
                activate_at
                end_at
                cron
                progression
            }
            device_id
            device {
                is_accessible
                reachable_by
            }
            status
            progression
            created_at
            sent_at
            started_at
            done_at
            sending_attempt_count
            updated_at
            deleted_at
        }
    }
`;
