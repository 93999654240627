import gql from 'graphql-tag';

export const VIEW_DEVICE_TYPES_BY_PROJECT_ID = gql`
    query viewDevicesTypesByProjectId($projectId: Int64!, $filters: FiltersInput) {
        deviceTypesByProjectId(projectId:$projectId, filters: $filters) {
            data {
                id
                name
                icon
                asset_type_id
                project_id
                meta_data
                default_configuration
                should_self_provision_has_mps
                is_accessible
                is_gateway
                is_lorawan
                updated_at
                deleted_at
                versions {
                    id
                    identifier
                    name
                    revision
                    configuration
                    device_type_id
                    version_parent_id
                    aws
                }
            }
            pagination {
                total
                last_page
            }
        }
    }
`;

export const SUBSCRIPTION_DEVICE_TYPES = gql`
    subscription liveDeviceType($projectId: Int64!) {
        liveDeviceType(projectId: $projectId) {
            id
            name
            icon
            asset_type_id
            project_id
            meta_data
            default_configuration
            should_self_provision_has_mps
            is_accessible
            is_gateway
            is_lorawan
            updated_at
            deleted_at
            versions {
                id
                identifier
                name
                revision
                configuration
                device_type_id
                version_parent_id
                aws
            }
        }
    }
`;

export const CREATE_DEVICE_TYPE = gql`
    mutation storeDeviceType($input: DeviceTypeInput!) {
        storeDeviceType(input: $input) {
            id
            name
            icon
            asset_type_id
            project_id
            meta_data
            default_configuration
            should_self_provision_has_mps
            is_accessible
            is_gateway
            is_lorawan
            updated_at
            deleted_at
        }
    }
`;

export const UPDATE_DEVICE_TYPE = gql`
    mutation updateDeviceType($id: Int!, $input: DeviceTypeInput!) {
        updateDeviceType(id: $id, input: $input) {
            id
            name
            icon
            asset_type_id
            project_id
            meta_data
            default_configuration
            should_self_provision_has_mps
            is_accessible
            is_gateway
            is_lorawan
            updated_at
            deleted_at
            versions {
                id
                identifier
                name
                revision
                configuration
                device_type_id
                version_parent_id
                aws
            }
        }
    }
`;

export const DELETE_DEVICE_TYPE = gql`
    mutation deleteDeviceType($id: Int!) {
        deleteDeviceType(id: $id) {
            id
            name
            icon
            asset_type_id
            project_id
            meta_data
            default_configuration
            should_self_provision_has_mps
            is_accessible
            is_gateway
            is_lorawan
            updated_at
            deleted_at
        }
    }
`;
