
import gql from 'graphql-tag';

export const STORE_REGISTRY = gql`
    mutation storeRegistry($input: RegistryInput!) {
        storeRegistry(input: $input) {
            id
            project_id
            name
            cuid
            configuration
            provisioning_secret
            provisioning_end_at
            provisioning_start_at
            cloud_provider_id
            cloud_provider {
                name
                configuration
            }
            topics {
                id
                pub_sub_topic_id
                name
                type
                sub_type_name
                configuration
            }
            device_types {
                id
                asset_type_id
                name
                icon
                meta_data
                default_configuration
                pivot  {
                  provisioning_quantity
                  device_count
                }
            }
            updated_at
            deleted_at
        }
    }
`;
export const UPDATE_REGISTRY = gql`
    mutation updateRegistry($registryId: Int64!, $input: RegistryInput!) {
        updateRegistry(registryId: $registryId, input: $input) {
            id
            project_id
            name
            cuid
            configuration
            provisioning_secret
            provisioning_end_at
            provisioning_start_at
            cloud_provider_id
            cloud_provider {
                name
                configuration
            }
            topics {
                id
                pub_sub_topic_id
                name
                type
                sub_type_name
                configuration
            }
            device_types {
                id
                asset_type_id
                name
                icon
                meta_data
                default_configuration
                pivot  {
                  provisioning_quantity
                  device_count
                }
            }
            updated_at
            deleted_at
        }
    }
`;

export const SUBSCRIPTION_REGISTRY = gql`
    subscription liveRegistry($projectId: Int64!) {
        liveRegistry(projectId: $projectId) {
            id
            project_id
            name
            cuid
            configuration
            provisioning_secret
            provisioning_end_at
            provisioning_start_at
            cloud_provider_id
            cloud_provider {
                name
                configuration
            }
            topics {
                id
                pub_sub_topic_id
                name
                type
                sub_type_name
                configuration
            }
            device_types {
                id
                asset_type_id
                name
                icon
                meta_data
                default_configuration
                pivot {
                  provisioning_quantity
                  device_count
                }
            }
            updated_at
            deleted_at
        }
    }
`;

export const UPDATE_REGISTY_TOPIC = gql`
    mutation updateRegistryTopic($id: Int64!, $projectId: Int64!, $input: RegistryTopicInput!) {
        updateRegistryTopic(id: $id, projectId: $projectId, input: $input) {
            id
            registry_id
            pub_sub_topic_id
            pub_sub_topic {
                name
                status
                inbound
            }
            type
            sub_type_name
            configuration
        }
    }
`;

export const CREATE_REGISTRY_TOPIC = gql`
    mutation storeRegistryTopic($registryId: Int64!, $projectId: Int64!, $input: RegistryTopicInput!) {
        storeRegistryTopic(registryId: $registryId, projectId: $projectId, input: $input) {
            id
            registry_id
            pub_sub_topic_id
            pub_sub_topic {
                name
                status
                inbound
            }
            type
            sub_type_name
            configuration
        }
    }
`;
