import gql from 'graphql-tag';

export const VIEW_RECEIVERS = gql`
    query viewReceivers($projectId: Int64!, $filters: FiltersInput) {
        receivers(projectId: $projectId, filters: $filters) {
            data {
                id
                name
                adapter_type
                connection_string
                is_enabled
            }
            pagination {
                total
                last_page
            }
        }
    }
`;

export const CREATE_RECEIVER = gql`
    mutation storeReceiver($projectId: Int64!, $input: ReceiverInput!) {
        storeReceiver(projectId: $projectId, input: $input) {
            id
            name
            adapter_type
            connection_string
            is_enabled
        }
    }
`;

export const UPDATE_RECEIVER = gql`
    mutation updateReceiver($projectId: Int64!, $id: Int64!, $input: ReceiverInput!) {
        updateReceiver(projectId: $projectId, id: $id, input: $input) {
            id
            name
            adapter_type
            connection_string
            is_enabled
        }
    }
`;

export const DELETE_RECEIVER = gql`
    mutation deleteReceiver($projectId: Int64!, $id: Int64!) {
        deleteReceiver(projectId: $projectId, id: $id) {
            id
            name
            adapter_type
            connection_string
            is_enabled
        }
    }
`;

export const VIEW_RECEIVER_STATUS = gql`
    query viewReceiverStatus($projectId: Int64!, $receiverId: Int64!) {
        receiverStatus(projectId: $projectId, id: $receiverId) {
            connected
        }
    }
`;
