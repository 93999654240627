<template>
    <div class="tile is-parent is-vertical">
        <div class="tile is-child box tile-box is-dark">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'tile-box',
};
</script>
