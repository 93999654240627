import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import vuexI18n from 'vuex-i18n';
import Vuelidate from 'vuelidate';

import { getMainDefinition } from 'apollo-utilities';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { concat, split } from 'apollo-link';
import { WebSocketLink } from 'apollo-link-ws';
import VueApollo from 'vue-apollo';

import { gatewayConnector, authTokenWS } from '@/plugins/gateway-connector/authMiddleware';
import Configuration from './configuration';

import Global from './global.js';

Vue.config.productionTip = false;

Vue.use(Global, { store, router });
Vue.use(Toast, {
    timeout: 5000,
    draggable: false,
    position: 'bottom-right'
});
Vue.use(Vuelidate);

window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

Vue.use(vuexI18n.plugin, store);

Vue.i18n.fallback('en');
Vue.i18n.set('en');

// OAuth
import OAuthPlugin from './plugins/oauth';
Vue.use(OAuthPlugin, { store, router });

Vue.use(VueApollo);

const cache = new InMemoryCache();
const httpLink = new HttpLink({
    uri: `${Configuration.VUE_APP_GRAPHQL_URL}/graphql`
});

const authMiddleware = gatewayConnector(store, 'oauth/accessToken');
const authToken = authTokenWS(store, 'oauth/accessToken');

const wsLink = new WebSocketLink({
    uri: `${Configuration.VUE_APP_GRAPHQL_WEB_SOCKET}`,
    options: {
        reconnect: true,
        reconnectionAttempts: 10,
        connectionParams: authToken,
    },
});

const link = split(
    // split based on operation type
    ({ query }) => {
        const definition = getMainDefinition(query);
        return definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription';
    },
    wsLink,
    httpLink
);

const apolloClient = new ApolloClient({
    cache,
    link: concat(authMiddleware.authMiddleware, link)
});

const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
});

new Vue({
    router,
    store,
    apolloProvider,
    render: h => h(App)
}).$mount('#app');

export const apollo = apolloClient;
