import {
    SUBSCRIPTION_REGISTRY,
    CREATE_REGISTRY_TOPIC,
    UPDATE_REGISTY_TOPIC,
} from '../../schema/registry';

export default {
    namespaced: true,
    state: {
        registries: [],
        registry_id: undefined,
    },

    getters: {
        registry_id: (state) => state.registry_id,
        //Selected (current) project of the selected Business Unit

        registries: (state) => state.registries,

        registry: (state) => {
            if (
                state.registries.length === 0 ||
                state.registry_id === undefined
            )
                return null;

            return state.registries.find(
                (item) => item.id === state.registry_id,
            );
        },

        exist: (state) => (id) => {
            return state.registries.findIndex((item) => item.id === id) !== -1;
        },
    },

    mutations: {
        set_registries: (state, registries) => {
            state.registries = registries;
        },

        set_registry_by_id: (state, registry_id) => {
            state.registry_id = registry_id;
        },

        add_registry_to_project: (state, registry) => {
            state.registries.push(registry);
        },
        update_registry: (state, registry) => {
            const index = state.registries.findIndex(
                (item) => item.id === registry.id,
            );
            if (index !== -1) {
                state.registries.splice(index, 1, registry);
            } else {
                state.registries.push(registry);
            }
        },

        update_registry_device_types_limits: (state, payload) => {
            if (state.registry_id === payload.registry_id) {
                const index = state.registries.findIndex(
                    (item) => item.id === state.registry_id,
                );
                state.registries[index].device_types = payload.types;
            }
        },

        update_topic_by_id(state, topic) {
            const indexReg = state.registries.findIndex(
                (item) => item.id === topic.registry_id,
            );
            if (indexReg !== -1) {
                let reg = state.registries[indexReg];
                const index = reg.topics.findIndex(
                    (item) => item.id === topic.id,
                );
                if (index !== -1) {
                    reg.topics.splice(index, 1, topic);
                } else {
                    reg.topics.push(topic);
                }
            } else {
                console.error(
                    'Oups, registry not found.. (update_topic_by_id)',
                );
            }
        },
    },
    actions: {
        subscribe: ({ commit, rootGetters }, payload) => {
            rootGetters.apollo
                .subscribe({
                    query: SUBSCRIPTION_REGISTRY,
                    variables: {
                        projectId: payload.project_id,
                    },
                })
                .subscribe({
                    next(message) {
                        commit('update_registry', message.data.liveRegistry);
                    },
                    error(error) {
                        console.error('error', error);
                    },
                });
        },

        storeOrUpdateTopic: ({ commit, rootGetters }, payload) => {
            const type = payload.create
                ? 'storeRegistryTopic'
                : 'updateRegistryTopic';

            return new Promise((resolve, reject) => {
                rootGetters.apollo
                    .mutate({
                        mutation: payload.create
                            ? CREATE_REGISTRY_TOPIC
                            : UPDATE_REGISTY_TOPIC,
                        variables: {
                            id: payload.id,
                            registryId: payload.registryId,
                            projectId: payload.projectId,
                            input: payload.input,
                        },
                    })
                    .then((response) => {
                        commit('update_topic_by_id', response.data[type]);
                        resolve(response.data[type]);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
    },
};
