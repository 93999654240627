
import axios from "axios";
import log from '../../logger';


import {differenceInMilliseconds, isAfter, subMinutes, addSeconds, parseISO, formatISO, isValid} from "date-fns";

import storageFactory from "../../storage";

const storage = storageFactory.make();

import InvitationsStore from './modules/invitation'

function clear_token(state)
{
    log("clear_token!");
    state.accessToken = null;
    state.tokensExpiry = null;

    storage.setItem("accessToken", null);
    storage.setItem("access_token", null);

    storage.setItem("tokenType", null);
    storage.setItem("token_type", null);
    storage.setItem("tokensExpiry", 0);
    storage.setItem("expires_in", 0);
}

export default {

    namespaced: true,

    modules: {
        invitations: InvitationsStore
    },

    state: {
        tokenType: storage.getItem("tokenType"),
        accessToken: storage.getItem("accessToken"),
        tokensExpiry: storage.getItem("tokensExpiry"),
        authorizeRoute: process.env.VUE_APP_OAUTH_SERVER_URL + process.env.VUE_APP_OAUTH_AUTHORIZE_ROUTE,
        logoutRoute: process.env.VUE_APP_OAUTH_SERVER_URL + process.env.VUE_APP_OAUTH_LOGOUT_ROUTE,
        checkRoute: process.env.VUE_APP_OAUTH_SERVER_URL + process.env.VUE_APP_OAUTH_CHECK_ROUTE,
        projectSelectorRoute: process.env.VUE_APP_OAUTH_SERVER_URL + process.env.VUE_APP_OAUTH_PROJECT_SELECTOR_ROUTE,
        userAccountRoute: process.env.VUE_APP_OAUTH_SERVER_URL,

        user: null,
    },

    getters: {
        /*tokensExpiry: state => state.tokensExpiry,*/
        accessToken: state => state.accessToken,

        isOAuthTokenValid: (state) => () => {
            let tokenExpiryDate = parseISO(state.tokensExpiry);

            if (!isValid(tokenExpiryDate)) {
                // eslint-disable-next-line
                log("No token expiry date. user probably never logged in");
                return false;
            }

            //If the token has expired or will expire in the next 10 minutes
            let oneMinutesBeforeExpiry = subMinutes(tokenExpiryDate, 1);
            const now = new Date();

            //If the token has expired or will expire in the next 1 minutes
            if (isAfter(now, oneMinutesBeforeExpiry)) {
                // eslint-disable-next-line
                log("Token expired/will expire in the next 1 minutes");
                return false;
            }
            //log("Token OK", state.tokensExpiry);

            return true;
        },
        currentUser: (state) => state.user,
        currentUserIsAdmin: (state) => {
            return state.user.roles.findIndex(role => role.name === 'container_admin') !== -1;
        },
        authorization_token: (state) => {
            return state.tokenType + " " + state.accessToken;
        },
    },

    mutations: {
        update_tokens(state, tokenData)
        {
            //log(tokenData);

            storage.setItem("accessToken", tokenData.access_token || tokenData.accessToken); //sometimes tokens are in snake case and other times they are in camelcase :/
            storage.setItem("access_token", tokenData.access_token || tokenData.accessToken);
            state.accessToken = storage.getItem("accessToken");

            storage.setItem("tokenType", tokenData.token_type || tokenData.tokenType);
            storage.setItem("token_type", tokenData.token_type || tokenData.tokenType);
            state.tokenType = storage.getItem("token_type");

            const tokensExpiry = addSeconds(new Date(), tokenData.expires_in || tokenData.expiresIn);
            state.tokensExpiry = formatISO(tokensExpiry);
            storage.setItem("tokensExpiry", state.tokensExpiry);
            storage.setItem("expires_in", state.tokensExpiry);


            let oneMinutesBeforeExpiry = subMinutes(tokensExpiry, 1);
            const now = new Date();

            log("Setup timer for logout! in " + (differenceInMilliseconds(oneMinutesBeforeExpiry, now)/60000) + " mins" );
            setTimeout(function () {
                clear_token(state);
            }, differenceInMilliseconds(oneMinutesBeforeExpiry, now));
        },

        clear_token(state) {
            clear_token(state);
        },

        set_user(state, user) {
            state.user = user;
        },
    },

    actions: {

        get_current_user (context) {

            return new Promise((resolve, reject) => {

                axios.get(
                    process.env.VUE_APP_OAUTH_SERVER_URL + '/api/v1/user',
                    {
                        'headers': {
                            'Authorization': context.getters.authorization_token,
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                        }
                    }
                )
                    .then(response => {
                        context.commit('set_user', response.data.data);
                        resolve();
                    })
                    .catch(error => {
                        clear_token(context.state);
                        reject(error);
                    });

            });
        },

        /*check (context) {
            return new Promise((resolve, reject) => {

                axios.get(context.state.checkRoute, {
                    headers: {
                        'Authorization': context.getters.authorization_token,
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        //'Access-Control-Allow-Origin': '*',
                        //'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
                    },
                })
                    .then(response => {
                        //context.commit('clear_token');
                        resolve(response);
                    })
                    .catch(response => {
                        reject(response);
                    });

            });
        },*/

        /*logout (context) {

            return new Promise((resolve, reject) => {

                axios.get(context.state.logoutRoute, {
                    headers: {
                        'Authorization': context.getters.authorization_token,
                        //'Access-Control-Allow-Origin': '*',
                        //'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
                    },
                })
                    .then(response => {
                        context.commit('clear_token');
                        resolve(response);
                    })
                    .catch(response => {
                        reject(response);
                    });

            });
        },*/
    }
}
