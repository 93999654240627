<template>
    <div class="tile is-ancestor is-vertical">
        <div class="tile is-12">
            <tile-box>

                <div class="columns">
                    <div class="column">
                        <h2 class="title is-2">Provisioning for {{ registry.name }}</h2>
                        <p class="subtitle">
                            Active from&nbsp;
                            <time :datetime="registry.provisioning_start_at">
                                {{ registry.provisioning_start_at | formatDate }}
                            </time>
                            &nbsp;to&nbsp;
                            <time :datetime="registry.provisioning_end_at">
                                {{ registry.provisioning_end_at | formatDate }}
                            </time>
                        </p>
                    </div>
                    <div class="colum has-text-right buttons">
                        <project-provisioning-file-download-button class="button is-primary is-outlined"
                            :registry="registry">

                        </project-provisioning-file-download-button>

                        <router-link class="button is-primary" :to="'/registries/' + registry.id + '/configuration/'">
                            <fa icon="eye" fixed-width />
                        </router-link>
                    </div>
                </div>

                <div class="columns is-multiline">
                    <div class="column is-3 is-narrow" v-for="(label, i) in device_types_labels" :key="i">
                        <radial-gradiant-chart :label="label" :value="device_types_quantities[i]"></radial-gradiant-chart>
                    </div>
                </div>

            </tile-box>
        </div>

        <div class="tile is-12">
            <tile-box>
                <device-states-heatmap></device-states-heatmap>
            </tile-box>
        </div>
    </div>
</template>

<script>
import AuthMixin from '@/plugins/oauth/components/AuthMixin';
import ProjectProvisioningFileDownloadButton from '../components/ProjectProvisioningFileDownloadButton';
import RadialGradiantChart from '../components/graph/RadialGradiantChart.vue';
import DeviceStatesHeatmap from '../components/graph/DeviceStatesHeatmap.vue';
import TileBox from '@/components/UI/TileBox.vue';

export default {
    name: 'Home',

    mixins: [AuthMixin],

    components: {
        TileBox,
        DeviceStatesHeatmap,
        RadialGradiantChart,
        ProjectProvisioningFileDownloadButton,
    },

    data() {
        return {
            loading: true,
            device_types_fields: [
                'name',
                {
                    name: '__slot:quantity-type-slot',
                    title: 'Quantity',
                },
            ],

        };
    },

    mounted() {
        this.loading = false;
    },

    computed: {
        project() {
            return this.$store.getters['businessUnit/project'];
        },

        registry() {
            return this.$store.getters['registries/registry'];
        },

        device_types() {
            if (this.registry)
                return this.registry.device_types;

            return [];
        },

        device_types_labels() {
            return this.device_types.map((device_type) => {
                if (device_type.pivot.provisioning_quantity === 0)
                    return null;

                return device_type.name;
            }).filter(x => x !== null);
        },

        device_types_quantities() {
            return this.device_types.map((device_type) => {
                if (device_type.pivot.provisioning_quantity === 0)
                    return null;

                return parseInt((device_type.pivot.device_count / device_type.pivot.provisioning_quantity) * 100);
            }).filter(x => x !== null);
        }

    },

    methods: {
    },
};
</script>
