import gql from 'graphql-tag';

export const VIEW_PUB_SUB_TOPICS = gql`
    query viewPubSubTopics($projectId: Int64!) {
        pubSubTopics(projectId: $projectId) {
            data {
                id
                name
                default_type
            }
        }
    }
`;

export const VIEW_CONSUMER_TOPICS = gql`
    query viewConsumerTopics($projectId: Int64!, $receiverId: Int64!, $filters: FiltersInput) {
        consumerTopics(projectId: $projectId, receiverId: $receiverId , filters: $filters) {
            data {
                id
                pubsub_topic_id
                pubsub_topic_name
                external_topic_name
                ingoing
            }
            pagination {
                total
                last_page
            }
        }
    }
`;

export const CREATE_CONSUMER_TOPIC = gql`
    mutation storeConsumerTopic($projectId: Int64!, $receiverId: Int64!, $input: ConsumerTopicInput!) {
        storeConsumerTopic(projectId: $projectId, receiverId: $receiverId, input: $input) {
            id
            pubsub_topic_id
            pubsub_topic_name
            external_topic_name
            ingoing
        }
    }
`;

export const UPDATE_CONSUMER_TOPIC = gql`
    mutation updateConsumerTopic($projectId: Int64!, $receiverId: Int64!, $id: Int64!, $input: ConsumerTopicInput!) {
        updateConsumerTopic(projectId: $projectId, receiverId: $receiverId, id: $id, input: $input) {
            id
            pubsub_topic_id
            pubsub_topic_name
            external_topic_name
            ingoing
        }
    }
`;

export const DELETE_CONSUMER_TOPIC = gql`
    mutation deleteConsumerTopic($projectId: Int64!, $receiverId: Int64!, $id: Int64!) {
        deleteConsumerTopic(projectId: $projectId, receiverId: $receiverId, id: $id) {
            id
            pubsub_topic_id
            pubsub_topic_name
            external_topic_name
            ingoing
        }
    }
`;