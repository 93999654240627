<template>
    <div id="app" class="dashboard">
        <!-- left panel -->
        <div
            class="side-bar has-text-dark"
            v-if="isOAuthTokenValid"
            :class="{ collapse: collapse }"
        >
            <div class="logo-name-wrapper">
                <div class="logo-name">
                    <fa icon="dice-d20" fixed-width></fa>
                    <span class="logo-name__name">Hub</span>
                </div>
                <div class="logo-name__button" @click="toggleMenu">
                    <fa
                        v-if="collapse"
                        icon="angle-double-right"
                        fixed-width
                    ></fa>
                    <fa v-else icon="angle-left" fixed-width></fa>
                </div>
            </div>
            <ul class="side-menu-list">
                <router-link
                    tag="li"
                    to="/oauth/project-selector"
                    exact
                    v-if="!project"
                    class="side-menu-item"
                >
                    <span class="bx side-menu-item-icon">
                        <fa icon="building" fixed-width></fa>
                    </span>
                    <span class="side-menu-item-text">Select Project</span>
                </router-link>

                <router-link
                    tag="li"
                    to="/"
                    exact
                    v-if="project && registry"
                    class="side-menu-item"
                >
                    <span class="bx side-menu-item-icon">
                        <fa icon="home" fixed-width></fa>
                    </span>
                    <span class="side-menu-item-text">Dashboard</span>
                </router-link>

                <router-link
                    tag="li"
                    v-if="project"
                    class="side-menu-item"
                    to="/registries"
                >
                    <span class="bx side-menu-item-icon">
                        <fa icon="clipboard-list" fixed-width></fa>
                    </span>
                    <span class="side-menu-item-text">Registry</span>
                </router-link>

                <router-link
                    tag="li"
                    v-if="project && registry"
                    class="side-menu-item"
                    to="/devices"
                >
                    <span class="bx side-menu-item-icon">
                        <fa icon="blog" fixed-width></fa>
                    </span>
                    <span class="side-menu-item-text">Devices</span>
                </router-link>

                <router-link
                    tag="li"
                    v-if="project && registry"
                    class="side-menu-item"
                    to="/gateways"
                >
                    <span class="bx side-menu-item-icon">
                        <fa icon="network-wired" fixed-width></fa>
                    </span>
                    <span class="side-menu-item-text">Gateways</span>
                </router-link>

                <router-link
                    tag="li"
                    v-if="project"
                    class="side-menu-item"
                    to="/device-types"
                >
                    <span class="bx side-menu-item-icon">
                        <fa icon="cubes" fixed-width></fa>
                    </span>
                    <span class="side-menu-item-text">Device Types</span>
                </router-link>

                <router-link
                    tag="li"
                    v-if="project"
                    class="side-menu-item"
                    to="/actions"
                >
                    <span class="bx side-menu-item-icon">
                        <fa icon="file-alt" fixed-width></fa>
                    </span>
                    <span class="side-menu-item-text">Actions</span>
                </router-link>

                <router-link
                    tag="li"
                    v-if="project && registry"
                    class="side-menu-item"
                    to="/devices-commands"
                >
                    <span class="bx side-menu-item-icon">
                        <fa icon="tasks" fixed-width></fa>
                    </span>
                    <span class="side-menu-item-text">Commands</span>
                </router-link>

                <router-link
                    tag="li"
                    v-if="project"
                    class="side-menu-item"
                    to="/pub-sub-topics"
                >
                    <span class="bx side-menu-item-icon">
                        <fa icon="link" fixed-width></fa>
                    </span>
                    <span class="side-menu-item-text">PubSub</span>
                </router-link>

                <router-link
                    tag="li"
                    v-if="project && connectorsActivated"
                    class="side-menu-item"
                    to="/connectors"
                >
                    <span class="bx side-menu-item-icon">
                        <fa icon="plug" fixed-width></fa>
                    </span>
                    <span class="side-menu-item-text">Connectors</span>
                </router-link>
            </ul>
        </div>

        <!-- main section -->
        <div class="dashboard-main is-scrollable">
            <nav class="navbar is-fixed-top">
                <div class="navbar-brand">
                    <a
                        role="button"
                        class="navbar-burger"
                        aria-label="menu"
                        aria-expanded="false"
                        :class="{ 'is-active': topMenuShown }"
                        @click="toggleTopMenu()"
                    >
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                    </a>
                </div>

                <div class="navbar-menu" :class="{ 'is-active': topMenuShown }">
                    <navbar-device-search></navbar-device-search>

                    <div class="navbar-end">
                        <span class="navbar-item">
                            <registry-selector></registry-selector>
                        </span>
                        <span class="navbar-item">
                            <admin-menu-button></admin-menu-button>
                        </span>
                    </div>
                </div>
            </nav>

            <div class="section">
                <nav class="breadcrumb has-padding" aria-label="breadcrumbs">
                    <ul>
                        <li>
                            <router-link to="/" v-if="project">{{
                                project.name
                            }}</router-link>
                        </li>

                        <li
                            v-for="(breadcrumb, idx) in breadcrumb_list"
                            :key="idx"
                            :class="{
                                'is-active': idx === breadcrumb_list.length,
                            }"
                        >
                            <router-link :to="breadcrumb.link">{{
                                breadcrumb.name
                            }}</router-link>
                        </li>
                    </ul>
                </nav>

                <transition name="slide-fade">
                    <keep-alive :include="['devices-index', 'gateway-index']">
                        <router-view />
                    </keep-alive>
                </transition>
            </div>

            <!-- the footer will take up all unused space at the bottom -->
            <footer class="footer">
                <div class="content has-text-centered">
                    <p class="has-text-white">
                        Dimonoff inc. ©
                        <span>{{ new Date().getFullYear().toString() }}</span
                        >. All rights reserved. {{ version }}
                    </p>
                </div>
            </footer>
        </div>
    </div>
</template>

<script>
import NavbarDeviceSearch from './components/NavbarDeviceSearch';
import Configuration from './configuration';
import AdminMenuButton from './components/AdminMenuButton';
import RegistrySelector from './components/RegistrySelector.vue';

console.info(
    'ENV:',
    process.env.NODE_ENV,
    'VERSION:',
    Configuration.VUE_APP_VERSION,
);

export default {
    name: 'App',
    components: { RegistrySelector, AdminMenuButton, NavbarDeviceSearch },
    data: function () {
        return {
            breadcrumb_list: [],
            topMenuShown: false,
            version:
                (Configuration.VUE_APP_VERSION || '') +
                ' (' +
                process.env.NODE_ENV +
                ')',
        };
    },

    created() {
        this.initBreadcrumbs();
    },

    async mounted() {
        if (this.isOAuthTokenValid) {
            await this.initStore();
        }
    },

    watch: {
        $route(to) {
            this.initBreadcrumbs();

            if (this.breadcrumb_list.length === 0) return;

            for (let i = 0, c = this.breadcrumb_list.length; i < c; i++) {
                let name = this.breadcrumb_list[i].name.toString();
                if (name.startsWith(':')) {
                    let param_name = name.substring(1);
                    this.breadcrumb_list[i].name = to.params[param_name];
                }
                const routes = this.breadcrumb_list[i].link
                    .split('/')
                    .filter((n) => n);

                let new_link = '';
                for (let r = 0, cr = routes.length; r < cr; r++) {
                    new_link += '/';
                    if (routes[r].startsWith(':')) {
                        let param_name = routes[r].substring(1);
                        new_link += to.params[param_name];
                    } else {
                        new_link += routes[r];
                    }
                }
                this.breadcrumb_list[i].link = new_link;
            }
        },

        async isOAuthTokenValid(newValue) {
            if (newValue) {
                await this.initStore();
            }
        },
    },

    computed: {
        isOAuthTokenValid() {
            return this.$store.getters['oauth/isOAuthTokenValid']();
        },
        business_unit() {
            return this.$store.getters['businessUnit/business_unit'];
        },
        project() {
            return this.$store.getters['businessUnit/project'];
        },
        registry() {
            return this.$store.getters['registries/registry'];
        },
        collapse() {
            return this.$store.getters['ui/collapse'];
        },
        connectorsActivated() {
            return this.$store.getters['connectors/activated'];
        },
    },

    methods: {
        initBreadcrumbs() {
            if (this.$route.meta.breadcrumb)
                this.breadcrumb_list = JSON.parse(
                    JSON.stringify(this.$route.meta.breadcrumb),
                );
            else this.breadcrumb_list = [];
        },

        async initStore() {
            this.$store.commit('apollo', this.$apollo);

            //TODO: validate session / catch authorization errors: "GraphQL error: access denied"

            this.$store.dispatch('utilities/load_protocols').then();
            this.$store.dispatch('utilities/load_comm_methods').then();
            this.$store.dispatch('utilities/loadAdapterTypes');
            this.$store.dispatch('utilities/load_cloud_provider').then();

            // we prevent the project switch if the project in the route param is different than the current project
            const searchParams = new URLSearchParams(window.location.search);
            const project_id = parseInt(searchParams.get('project'));

            if (
                this.project !== undefined &&
                (isNaN(project_id) || this.project.id === project_id)
            ) {
                try {
                    await this.$store.dispatch('businessUnit/switch_project', {
                        project_id: this.project.id,
                        registry_id: this.registry ? this.registry.id : null,
                    });
                } catch (e) {
                    console.error(e);
                } finally {
                    if (this.registry) {
                        this.$store.commit(
                            'registries/set_registry_by_id',
                            this.registry.id,
                        );
                    } else
                        this.$store.commit(
                            'registries/set_registry_by_id',
                            null,
                        );
                }
            }
        },

        toggleMenu() {
            this.$store.commit('ui/toggle_menu_collapse');
        },

        toggleTopMenu() {
            this.topMenuShown = !this.topMenuShown;
        },
    },
};
</script>

<style lang="scss">
@import 'src/scss/variables';
@import 'src/scss/side-bar';
@import 'src/scss/ace';

//MODAL
$modal-card-head-background-color: $dark;
$modal-card-body-background-color: $dark;

//FILE
$file-border-color: $dark !important;
$file-name-border-color: $dark !important;
$file-cta-color: $text-secondary;

//O-STEPS
$steps-default-color: $dark-invert;
$steps-completed-color: $primary;
$steps-active-color: $primary;

@import '~bulma';
@import '~bulma-dashboard/src/bulma-dashboard';
@import '~bulma-o-steps/bulma-steps';
@import '~vue-multiselect/dist/vue-multiselect.min.css';

// SWEETALERT2
@import '~sweetalert2/src/variables';

//MULTISELECT
.input,
.textarea,
.select select,
.multiselect div,
input.multiselect__input {
    background-color: $body-background-color !important;
    border-color: $dark;
    border-radius: 0;
    color: $secondary !important;
}

.multiselect__input,
.multiselect__single,
pre {
    background-color: $body-background-color !important;
}

.multiselect__option--selected {
    background: $dark;
    color: $secondary !important;
}

.multiselect__option--highlight {
    background: $dark;
}

.multiselect__option--selected.multiselect__option--highlight {
    background: $dark;
}

.multiselect__content-wrapper {
    border-color: $primary !important;
    box-shadow:
        0 0.5em 0.25em 0.25em rgba($primary, 0.2),
        0 0 1px 1px rgba($primary, 0.2);
}

.modal {
    position: fixed !important;
}

$swal2-background: $dark !important;
$swal2-confirm-button-background-color: $primary !important;
$swal2-success: $primary !important;
$swal2-success-border: $primary !important;
$swal2-error: $danger !important;
$swal2-title-color: $primary !important;
$swal2-content-color: $secondary !important;

@import '~sweetalert2/src/sweetalert2';

html,
body {
    height: 100%;
}

.menu {
    overflow-y: auto;
}

// body{
//     display: flex;
// }
.dashboard {
    height: 100vh;
}

.is-fullwidth {
    width: 100%;
}

.is-darker {
    background-color: rgba(10, 10, 10, 0.2);
    border: none;
}

.select,
.select select {
    width: 100%;
}

.has-padding {
    padding: 1rem;
}

.has-padding-2 {
    padding: 2rem;
}

.has-padding-left {
    padding-left: 1rem;
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
    transition: all 0.6s ease;
}

.slide-fade-leave-active {
    transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter {
    transform: translateY(-10px);
    opacity: 0;
}

.slide-fade-leave-to {
    transform: translateY(-10px);
    opacity: 0;
    position: absolute;
}

.notification > .corner-button {
    position: absolute;
    right: 1rem;
    top: 1rem;
}

.dark-title {
    color: $dark-invert !important;
}

.dark-text {
    color: $text-secondary !important;
}

.is-selected {
    border: 1px solid $primary;
    box-shadow:
        0 0.5em 1em -0.125em rgba($primary, 0.1),
        0 0px 0 1px rgba($primary, 0.02);
}

.flex-1 {
    flex: 1;
}

.dashboard-main .section {
    flex: 1;
}

.dashboard-main .footer {
    flex: none;
}

.is-text-center {
    text-align: center;
}

.navbar-item .field {
    margin-bottom: 0 !important;
}

.tile-box {
    background-color: #323c48;
    color: #c5c5c5;
    border-radius: 0;
    position: relative;
    padding: 1.25rem 2.5rem 1.25rem 1.5rem;
}

.vuetable-body tr:hover {
    background-color: $body-background-color;
    cursor: pointer;
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-side-enter-active {
    overflow: hidden;
    transition: all 0.6s ease;
}

.slide-side-enter {
    position: absolute;
    transform: translateX(100%);
    opacity: 0;
}

.slide-side-leave-active {
    display: none;
}

.slide-side-reverse-enter-active {
    overflow: hidden;
    transition: all 0.6s ease;
}

.slide-side-reverse-enter {
    position: absolute;
    transform: translateX(-100%);
    opacity: 0;
}

.slide-side-reverse-leave-active {
    display: none;
}

.error {
    color: $secondary;
    background: $danger !important;
    padding: 6px !important;
    border-radius: 2px !important;
    position: relative;
    display: inline-block !important;
    box-shadow: 1px 1px 1px $card-shadow;
    margin-top: 10px;
}

.error:before {
    content: '';
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid $danger;
    position: absolute;
    top: -10px;
}

.breadcrumb {
    margin-top: -3rem;
    margin-left: -3rem;
    margin-right: -3rem;
    background-color: #242c35;
    box-shadow: #242c35 5px 5px 5px 0;
    font-size: small;
    padding: 0.5rem;
}

.page-item.active {
    a.page-link-item {
        color: $secondary !important;
        font-weight: bold;
    }
}
</style>
