<template>
    <div>
        <div class="columns">
            <div class="column">
                <h3 class="title is-3">Device States Heatmap</h3>
            </div>
            <div class="column" v-if="registry_id">
                <registry-devices-multi-select :registry_id="registry_id"
                    v-model="devices_list"></registry-devices-multi-select>
            </div>
        </div>

        <heatmap v-if="devices_list.length" :series_data="series"></heatmap>
    </div>
</template>

<script>

import Heatmap from './Heatmap.vue';
import RegistryDevicesMultiSelect from '../RegistryDevicesMultiSelect.vue';

export default {
    name: 'device-states-heatmap',
    components: { RegistryDevicesMultiSelect, Heatmap },

    data() {
        return {
            devices_list: [],
            series: [],
            loading: false,
        };
    },

    watch: {
        devices_list() {
            this.$store.commit('ui/saveDevicesList', { registry_id: this.registry_id, devices: this.devices_list });
            this.load();
        },
        saved_devices_list() {
            this.devices_list = this.saved_devices_list;
        }
    },

    mounted() {
        this.devices_list = this.saved_devices_list ?? [];
    },

    computed: {
        project() {
            return this.$store.getters['businessUnit/project'] ?? null;
        },
        registry_id() {
            return this.$store.getters['registries/registry_id'] ?? null;
        },

        saved_devices_list() {
            if (this.registry_id === undefined)
                return null;

            return this.$store.getters['ui/savedDevicesListByRegistry'](this.registry_id) ?? null;
        },
    },

    methods: {
        async load() {

            if (this.loading) {
                return;
            }

            this.series = [];

            if (this.devices_list.length === 0) {
                return;
            }

            this.loading = true;
            for (let i = 0; i < this.devices_list.length; i++) {
                let heatmap = await this.$store.dispatch('devices/getDevicesStatesHeatmap', {
                    project_id: this.project.id,
                    device_id: this.devices_list[i].id,
                });

                this.series.push({
                    name: this.devices_list[i].name,
                    data: heatmap.map((item) => {
                        return {
                            x: item.timestamp,
                            y: item.value
                        };
                    })
                });
            }

            this.loading = false;
        }
    },
};

</script>
