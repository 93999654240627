<template>
    <div class="card-content">
        <div class="content">

            <nav class="level">
                <div class="level-item has-text-centered">
                    <div v-if="!designed_gateway">
                        <p class="heading">Device</p>

                        <p v-if="target_device" class="title">
                            {{ target_device.serial_number }}
                        </p>
                        <p v-if="target_device" class="subtitle">
                            <router-link class="button is-primary is-small"
                                :to="{ name: 'devices-edit', params: { id: target_device.id } }">
                                View
                            </router-link>
                        </p>
                        <p v-if="!target_device" class="title">Self</p>
                    </div>
                    <div v-if="designed_gateway">
                        <p class="heading">Gateway</p>

                        <p class="title">
                            ID: {{ designed_gateway }}
                        </p>
                        <p class="subtitle">
                            <router-link class="button is-primary is-small"
                                :to="{ name: 'devices-edit', params: { id: designed_gateway } }">
                                View
                            </router-link>
                        </p>
                    </div>
                </div>

                <div class="level-item has-text-centered">
                    <div>
                        <p class="heading">Wait</p>
                        <p class="title">{{ waitTime }}</p>
                        <p class="subtitle" title="Created At">{{ command.created_at | formatDate }}</p>
                    </div>
                </div>
                <div class="level-item has-text-centered">
                    <div>
                        <p class="heading">Duration</p>
                        <p class="title">{{ durationTime }}</p>
                        <p class="subtitle" v-if="command.started_at" title="Started At">{{ command.started_at | formatDate
                        }}</p>
                    </div>
                </div>
                <div class="level-item has-text-centered">
                    <div>
                        <p class="heading">Status</p>
                        <p class="title">{{ command.status }}</p>

                        <p v-if="command.done_at" class="subtitle" title="Done At">{{ command.done_at | formatDate }}</p>
                    </div>
                </div>
            </nav>

            <label v-if="command.sent_at && !command.started_at" class="label">Sent At</label>
            <p v-if="command.sent_at && !command.started_at">{{ command.sent_at | formatDate }}</p>

            <label v-if="command.log" class="label">Log</label>
            <p v-if="command.log">{{ command.log }}</p>

        </div>
    </div>
</template>

<script>
import { formatDistance } from 'date-fns';

export default {
    name: 'device-command-card-content',
    props: {
        command: {
            type: Object,
            required: true
        },
        device_id: {
            required: true
        },
    },
    data: () => {
        return {
            is_visible: false
        };
    },
    computed: {
        target_device() {
            if (
                this.command !== null
                && this.command.parameters !== null
                && this.command.parameters.target_device) {
                if (
                    this.command.parameters.target_device.id !== this.command.device_id &&
                    this.command.parameters.target_device.id !== this.device_id
                ) {
                    return this.command.parameters.target_device;
                }
            }
            return null;
        },

        designed_gateway() {
            if (
                this.command !== null
                && this.command.parameters !== null
                && this.command.parameters.target_device) {
                if (
                    this.command.parameters.target_device.id !== this.command.device_id &&
                    this.command.parameters.target_device.id === this.device_id
                ) {
                    return this.command.device_id;
                }
            }
            return null;
        },

        waitTime() {
            const createdAt = new Date(this.command.created_at);
            const startAt = this.command.started_at ? new Date(this.command.started_at) : new Date();
            return formatDistance(createdAt, startAt);
        },

        durationTime() {
            if (!this.command.started_at) return '-';

            const startAt = new Date(this.command.started_at);
            const doneAt = this.command.done_at ? new Date(this.command.done_at) : new Date();

            return formatDistance(startAt, doneAt);

        },

        isSuccess() {
            return this.command.status === 'success';
        },
        isPending() {
            return this.command.status === 'pending';
        },
        isError() {
            return this.command.status === 'failure';
        },

    },
};
</script>
